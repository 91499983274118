import React, { Fragment, useCallback, useContext } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
  Zoom
} from '@mui/material';
import customerFormStyles from 'containers/customer_comment_form/styles';
import customerFormText from 'containers/customer_comment_form/text';
import {
  BusRailDropdowns,
  FullPagfeCircleLoader,
  Inputs,
  GridWrapper,
  LinkComponent
} from 'components';
import { CustomerCommentFormContext } from 'context';
import { checkValidEmailPattern } from 'helpers';
import './recaptcha.css';

const GRID_SPACING = { xs: 2, md: 3 };
const GRID_COLUMN_SPACING = { xs: 4, sm: 8, md: 12 };

const CustomerCommentForm: React.FC = (): JSX.Element => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    mainTitle,
    paragraphs: { p1, p2, p3, p4 },
    personalInfoSection,
    radioGroupSection,
    reportForm
  } = customerFormText;
  const theme = useTheme();
  const formData = useContext(CustomerCommentFormContext);

  /**
   * Booleans used to toggle certain form controls visible or hidden.
   * Task -> Possibly clean up and simplify the logic 
   * */
  const isBus = formData?.state?.transitType?.value === radioGroupSection.transitType.radioGroupItems![0].value;
  const isRail = formData?.state?.transitType?.value === radioGroupSection.transitType.radioGroupItems![1].value;
  const isMicroTransit = formData?.state?.transitType?.value === radioGroupSection.transitType.radioGroupItems![2].value;
  const isBikeShare = formData?.state?.transitType?.value === radioGroupSection.transitType.radioGroupItems![3].value;
  const isGeneralComments = formData?.state?.transitType?.value === radioGroupSection.transitType.radioGroupItems![4].value;
  const transportationSelected = formData?.state?.transitType?.value.length != 0;
  const language = formData.state.language.value;

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha('submit');
    return token;
  }, [executeRecaptcha]);

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = Object.entries(formData.state).filter(([key, e]) => {
      if (key === 'email') {
        return e.required && e.value.length === 0 && !checkValidEmailPattern(e.value);
      }

      return e.required && e.value.length === 0;
    });
    
    const errordObject = Object.fromEntries(errors);
    if (Object.keys(errordObject).length > 0) {
      formData?.handleErrors && formData.handleErrors(true);
      window.scrollTo(0, 0);
    } else {
      handleReCaptchaVerify()
        .then((res) => formData.handleSubmitForm && formData.handleSubmitForm(res as string))
        .catch(e => console.error('Captcha Validation error', e));
    }
  };

  /**
   * Component wrappers for MUI Transition components.
   * Children of MUI transitions component need to return jsx separately defined
   *  */
  const FormTransitionsGroupDate = (
    <Grid item={true} xs={6} md={3} lg={2}>
      <Inputs form={reportForm.incidentDate} />
    </Grid>
  );

  const FormTransitionsGroupTime = (
    <Grid item={true} xs={6} md={3} lg={2}>
      <Inputs form={reportForm.incidentTime} />
    </Grid>
  );

  const FormTransitionsGroupBusRailLines = (
    <Grid item={true} xs={12} md={6}>
      <BusRailDropdowns type='lines' />
    </Grid>
  );

  const FormTranstionsGroupBusCarNumber = (
    <Grid item={true} xs={12} md={6}>
      <Inputs form={isBus ? reportForm.busNumber : reportForm.carNumber} />
    </Grid>
  );

  const FormTranstionsGroupBoardingLocation = (
    <Grid item={true} xs={12}>
      <Inputs form={reportForm.boardingLocation} />
    </Grid>
  );

  const FormTranstionsGroupBoardingLocationDropdowns = (
    <Grid item={true} xs={12}>
      <BusRailDropdowns type='boardingLocationDropDown' />
    </Grid>
  );

  const FormTranstionsGroupIncidentLocation = (
    <Grid item={true} xs={12}>
      <Inputs form={reportForm.generalCommentsIncidentLocation} />
    </Grid>
  );

  const FormTranstionsGroupDestinations = (
    <Grid item={true} xs={12}>
      <BusRailDropdowns type='destinations' />
    </Grid>
  );

  const FormTranstionsGroupDescription = (
    <Grid item={true} xs={12}>
      <Inputs form={reportForm.description} />
    </Grid>
  );

  const FormTranstionsGroupOperatorBadgeNumber = (
    <Grid item={true} xs={12}>
      <Inputs form={reportForm.operatorBadgeNumber} />
    </Grid>
  );

  const FormTranstionsGroupOperatorDescription = (
    <Grid item={true} xs={12}>
      <Inputs form={reportForm.operatorDescription} />
    </Grid>
  );

  const FormTranstionsGroupEmployeeDescription = (
    <Grid item={true} xs={12}>
      <Inputs form={reportForm.generalCommentsEmployeeDescription} />
    </Grid>
  );

  const FormTranstionsGroupLicensePlateNumber = (
    <Grid item={true} xs={12} md={6}>
      <Inputs form={reportForm.generalCommentsLicensePlateNumber} />
    </Grid>
  );

  const FormTranstionsGroupVehicleID = (
    <Grid item={true} xs={12} md={6}>
      <Inputs form={reportForm.generalCommentsVehicleID} />
    </Grid>
  );

  const FormTransitionsGroupMicroTransitZone = (
    <Grid item={true} xs={12}>
      <Inputs form={reportForm.microTransitZone} />
    </Grid>
  );

  const FormTransitionsGroupBikeShareStation = (
    <Grid item={true} xs={12} md={6}>
      <Inputs form={reportForm.bikeShareStation} />
    </Grid>
  );

  const FormTranstionsGroupBikeShareTAPInput = (
    <Grid item={true} xs={12} md={6}>
      <Inputs form={reportForm.tapCard} />
    </Grid>
  );

  const FormTransitionsGroupFollowUp = (
    <Grid item={true} xs={12}>
      <Inputs form={reportForm.followUp} />
    </Grid>
  );

  return (
    <Container sx={customerFormStyles(theme).container}>
      {formData.formSubmitted ? <FullPagfeCircleLoader /> : null}
      <Typography
        sx={() => customerFormStyles(theme).headers.pageTitle}
        gutterBottom
        borderBottom={1 / 2}
      >
        {mainTitle[language]}
      </Typography>
      <Typography
        gutterBottom
        variant='body2'
      >
        {p1[language].text}
      </Typography>
      <Typography
        gutterBottom
        variant='body2'
      >
        {p2[language].text}
        <Typography component={`span`}>
          <LinkComponent
            isReactRoute={false}
            url={p2[language].link}
          >
            {p2[language].link.replace('mailto:', '')}
          </LinkComponent>
          {p2[language].afterLinkText ? p2[language].afterLinkText : null}
        </Typography>
      </Typography>
      <Typography
        gutterBottom
        variant='body2'
        sx={() => customerFormStyles(theme).bold}
      >
        {p3[language].text}
      </Typography>
      <Typography
        gutterBottom
        sx={() => {
          return {
            ...customerFormStyles(theme).warningColor,
            ...customerFormStyles(theme).fontStyles.italic,
            ...customerFormStyles(theme).bold
          };
        }}
        variant='body2'
      >
        {p4[language].text}
      </Typography>
      <Box component='form' autoComplete='off' noValidate onSubmit={(event) => void submitForm(event)}>
        <GridWrapper container spacing={GRID_SPACING} columns={GRID_COLUMN_SPACING}>
          {personalInfoSection.map((form, i) => (
            <Fragment key={`info-form-${i}`}>
              {form?.title && (
                <GridWrapper item={true} xs={12}>
                  <Typography
                    gutterBottom
                    borderBottom={0.5}
                    sx={() => customerFormStyles(theme).headers.subTitles}
                  >
                    {form.title[language]}

                  </Typography>
                </GridWrapper>
              )}
              <GridWrapper item={true} xs={12} md={form.stateKey === 'address1' || form.stateKey === 'address2' ? 12 : 6}>
                <Inputs form={form} />
              </GridWrapper>
            </Fragment>
          ))}

          <GridWrapper item={true} xs={12}>
            <Typography
              gutterBottom
              borderBottom={0.5}
              sx={() => customerFormStyles(theme).headers.subTitles}
            >
              {radioGroupSection.title[language]}
            </Typography>
          </GridWrapper>
          <GridWrapper item={true} xs={12}>
            <Inputs form={radioGroupSection.transitType} />
          </GridWrapper>

          {/* {transportationSelected && isBus ? (
            <Zoom in={transportationSelected && isBus}>
              {FormTransitionsGroupWifi}
            </Zoom>
          ) : null} */}

          {transportationSelected ? (
            <GridWrapper container spacing={GRID_SPACING} item={true}>
              <Zoom in={transportationSelected}>
                {FormTransitionsGroupDate}
              </Zoom>
              <Zoom in={transportationSelected}>
                {FormTransitionsGroupTime}
              </Zoom>
            </GridWrapper>
          ) : null}

          {transportationSelected && (isBus || isRail) ? (
            <>
              <Zoom in={transportationSelected && (isBus || isRail)}>
                {FormTransitionsGroupBusRailLines}
              </Zoom>
              <Zoom in={transportationSelected && (isBus || isRail)}>
                {FormTranstionsGroupBusCarNumber}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && (isBus) ? (
            <>
              <Zoom in={transportationSelected && isBus}>
                {FormTranstionsGroupBoardingLocation}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && (isRail) ? (
            <>
              <Zoom in={transportationSelected && isRail}>
                {FormTranstionsGroupBoardingLocationDropdowns}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && isGeneralComments ? (
            <>
              <Zoom in={transportationSelected && isGeneralComments}>
                {FormTranstionsGroupVehicleID}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && isGeneralComments ? (
            <>
              <Zoom in={transportationSelected && isGeneralComments}>
                {FormTranstionsGroupLicensePlateNumber}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && isGeneralComments ? (
            <>
              <Zoom in={transportationSelected && isGeneralComments}>
                {FormTranstionsGroupIncidentLocation}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && (isBus || isRail) ? (
            <>
              <Zoom in={transportationSelected && (isBus || isRail)}>
                {FormTranstionsGroupDestinations}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && isMicroTransit ? (
            <>
              <Zoom in={transportationSelected && isMicroTransit}>
                {FormTransitionsGroupMicroTransitZone}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && isBikeShare ? (
            <>
              <Zoom in={transportationSelected && isBikeShare}>
                {FormTransitionsGroupBikeShareStation}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && isBikeShare ? (
            <>
              <Zoom in={transportationSelected && isBikeShare}>
                {FormTranstionsGroupBikeShareTAPInput}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && (isBus || isRail || isMicroTransit || isBikeShare || isGeneralComments) ? (
            <>
              <Zoom in={transportationSelected && (isBus || isRail || isMicroTransit || isBikeShare || isGeneralComments)}>
                {FormTranstionsGroupDescription}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && (isBus || isRail) ? (
            <>
              <GridWrapper item={true} xs={12}>
                <Typography
                  gutterBottom
                  borderBottom={0.5}
                  sx={() => customerFormStyles(theme).headers.subTitles}
                >
                  {reportForm?.operatorBadgeNumber?.title && reportForm.operatorBadgeNumber.title[language]}
                </Typography>
              </GridWrapper>
              <Zoom in={transportationSelected && (isBus || isRail)}>
                {FormTranstionsGroupOperatorBadgeNumber}
              </Zoom>
              <Zoom in={transportationSelected && (isBus || isRail)}>
                {FormTranstionsGroupOperatorDescription}
              </Zoom>
            </>
          ) : null}

          {transportationSelected && isGeneralComments ? (
            <>
              <Zoom in={transportationSelected && isGeneralComments}>
                {FormTranstionsGroupEmployeeDescription}
              </Zoom>
            </>
          ) : null}


          {transportationSelected && (
            <>
              <Zoom in={transportationSelected}>
                {FormTransitionsGroupFollowUp}
              </Zoom>
            </>
          )}
        </GridWrapper>


        <GridWrapper spacing={GRID_SPACING} item={true} xs={12}>
          <Box margin='normal' sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" type='submit'>Submit</Button>
          </Box>
        </GridWrapper>
      </Box>
    </Container>
  );
};

export default CustomerCommentForm;

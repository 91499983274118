import React, { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import api from '../../api';
import customerFormText from 'containers/customer_comment_form/text';

export interface FormValues {
  value: string;
  required: boolean;
}

export type CustomerCommentFormStateTypes = {
  state: {
    [key: string]: FormValues;
  }
}

type RailLinesResponse = {
  lineId: string;
  lineName: string;
  lineShortName: string;
}

type BusLinesResponse = {
  lineNumber: string;
  lineName: string;
}

type DestinationsResponse = {
  stopId: number | string;
  busLine?: string;
  busRailId?: string;
  lineId?: string;
  lineName?: string;
  lineShortName?: string;
  stopName: string;
}

type Destinations = {
  [key: string]: DestinationsResponse[] | [];
}

type FormSubmitResponse = {
  commentNumber: string | number;
  message: string;
  status: string;
}

export interface CustomerCommentContextTypes extends CustomerCommentFormStateTypes {
  busLines?: BusLinesResponse[] | [];
  destinations?: Destinations;
  formSubmitSuccess?: boolean;
  formSubmitted?: boolean;
  railLines?: RailLinesResponse[] | [];
  isErrors?: boolean;
  handleChange?: (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDateTime?: (key: string, date: Dayjs) => void;
  handleErrors?: (error: boolean) => void;
  handleLanguageChange?: (language: string) => void;
  handleSubmitForm?: (captcha: string) => void;
}

const dayJS = dayjs.extend(utc);
const date = dayJS.utc();
const DEFAULT_LANGUAGE = 'en';
const DEFAULT_STATE = 'CA';
export const DATE_FORMAT = 'YYYYMMDD HHmmss.000';

const customerCommentFormState: CustomerCommentFormStateTypes = {
  state: {
    address1: { value: '', required: true },
    address2: { value: '', required: false },
    tapCard: { value: '', required: false },
    busCarVehicleNumber: { value: '', required: false },
    busRailLine: { value: '', required: true },
    city: { value: '', required: true },
    dayPhone: { value: '', required: true },
    description: { value: '', required: true },
    destination: { value: '', required: true },
    email: { value: '', required: true },
    eveningPhone: { value: '', required: false },
    firstName: { value: '', required: true },
    followUp: { value: 'yes', required: true },
    incidentDateTime: { value: dayjs(date).local().format(DATE_FORMAT).toString(), required: true },
    language: { value: DEFAULT_LANGUAGE, required: true },
    lastName: { value: '', required: true },
    licensePlateNumber: { value: '', required: false },
    location: { value: '', required: true },
    microTransitZone: { value: '', required: true },
    operatorBadgeNumber: { value: '', required: false },
    operatorDescription: { value: '', required: false },
    state: { value: DEFAULT_STATE, required: true },
    transitType: { value: '', required: true },
    zip: { value: '', required: true }
  }
};

interface CustomerCommentFormContextProps {
  children: ReactNode;
}

export const CustomerCommentFormContext = createContext<CustomerCommentContextTypes>(customerCommentFormState);

const CustomerCommentFormProvider: React.FC<CustomerCommentFormContextProps> = ({ children }) => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState<boolean>(false);
  const [formData, setFormData] = useState(customerCommentFormState);
  const [isErrors, setIsErrors] = useState(false);
  const [busLines, setBusLines] = useState<BusLinesResponse[]>([]);
  const [destinations, setDestinations] = useState<Destinations>({ bus: [], rail: [] });
  const [railLines, setRailLines] = useState<RailLinesResponse[]>([]);
  const navigate = useNavigate();

  const formDataHandler = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      personalInfoSection,
      radioGroupSection,
      reportForm
    } = customerFormText;
    const isTransportationMethod = key === radioGroupSection.transitType.stateKey;
    const isLineSelected = key === 'busRailLine';
    const isEmail = key === personalInfoSection[3].stateKey;
    const isZip = key === personalInfoSection[10].stateKey;
    const isBikeShareTapCard = key === reportForm.tapCard.stateKey;
    const isFollowUp = key === reportForm.followUp.stateKey;
    const isBus = event.target.value === radioGroupSection.transitType.radioGroupItems![0].value;
    const isRail = event.target.value === radioGroupSection.transitType.radioGroupItems![1].value;
    const isMicroTransit = event.target.value === radioGroupSection.transitType.radioGroupItems![2].value;
    const isBikeShare = event.target.value === radioGroupSection.transitType.radioGroupItems![3].value;

    /**
     * Reset certain form values when 'bus' or 'rail' issue changes for radio buttons
     * This is to clear the form when either of these switches to the other 
     */
    if (isTransportationMethod) {
      setFormData({
        state: {
          ...formData.state,
          busRailLine: { value: '', required: isBus || isRail },
          busCarVehicleNumber: { value: '', required: false },
          destination: {
            value: '',
            required: isBus || isRail
          },
          description: { value: '', required: true },
          incidentDateTime: {
            value: dayjs(date).local().format(DATE_FORMAT).toString(),
            required: true
          },
          licensePlateNumber: { value: '', required: false },
          location: { value: '', required: isBus || isRail || isBikeShare },
          microTransitZone: { value: '', required: isMicroTransit },
          operatorBadgeNumber: { value: '', required: false },
          operatorDescription: { value: '', required: false },
          tapCard: { value: '', required: false },
          transitType: {
            value: isTransportationMethod ? event.target.value : formData.state.transitType.value,
            required: true
          }
        }
      });
    } else if (isLineSelected) {
      setFormData({
        state: {
          ...formData.state,
          busRailLine: { value: event.target.value, required: true },
          destination: {
            value: '',
            required: true
          }
        }
      });
    } else if (isEmail) {
      setFormData({
        state: {
          ...formData.state,
          email: { value: event.target.value, required: formData.state.followUp.value === 'yes' },
        }
      });
    } else if (isZip) {
      setFormData({
        state: {
          ...formData.state,
          zip: { value: event.target.value.replace(/[\D]/, ''), required: true },
        }
      });
    } else if (isBikeShareTapCard) {
      setFormData({
        state: {
          ...formData.state,
          tapCard: { value: event.target.value.replace(/[\D]/, ''), required: false },
        }
      });
    } else if (isFollowUp) {
      setFormData({
        state: {
          ...formData.state,
          email: {
            value: formData.state.email.value,
            required: event.target.value === 'yes'
          },
          followUp: { value: event.target.value, required: true },
        }
      });
    } else {
      setFormData({
        state: {
          ...formData.state,
          [key]: {
            value: event.target.value,
            required: formData.state[key].required
          }
        }
      });
    }
  };

  const formDataHandlerDateTime = (key: string, enterDate: Dayjs) => {
    const stringDate = dayjs(enterDate).format(DATE_FORMAT).toString();

    setFormData({
      state: {
        ...formData.state,
        [key]: {
          value: stringDate,
          required: formData.state[key].required
        }
      }
    });
  };

  const formErrorHandler = (error: boolean) => {
    setIsErrors(error);
  };

  const setLanguage = (language: string) => (
    setFormData({
      state: {
        ...formData.state,
        language: {
          value: language,
          required: true
        }
      }
    })
  );

  const initBusLines = async (): Promise<BusLinesResponse[] | []> => {
    try {
      const fetchedLines = await fetch(api.buslines, {
        method: 'GET',
        headers: {
          'x-api-key': api.bus_key,
          'Content-Type': 'application/json'
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const data = await fetchedLines.json();

      return data as Promise<BusLinesResponse[]>;
    } catch (error) {
      console.error('Fetch error: Bus Lines', error);
      return [];
    }
  };

  const initRailLines = async (): Promise<RailLinesResponse[] | []> => {
    try {
      const fetchedLines = await fetch(api.railLines, {
        method: 'GET',
        headers: {
          'x-api-key': api.rail_key,
          'Content-Type': 'application/json'
        }
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { data } = await fetchedLines.json();

      return data as Promise<RailLinesResponse[]>;
    } catch (error) {
      console.error('Fetch error: Rail Lines', error);
      return [];
    }
  };

  const initDestinations = async (id: string): Promise<DestinationsResponse[] | []> => {
    try {
      const isBus = formData.state.transitType.value === 'bus'
      const URL = isBus
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        ? `${api.bus_stops}?` + new URLSearchParams({ busLine: id })
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        : `${api.rail_stops}?` + new URLSearchParams({ line: id });

      const fetchedDestinations = await fetch(URL, {
        method: 'GET',
        headers: {
          'x-api-key': isBus ? api.bus_key : api.rail_key,
          'Content-Type': 'application/json'
        }
      });

      if (isBus) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data = await fetchedDestinations.json();

        return data as Promise<DestinationsResponse[]>;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { data } = await fetchedDestinations.json();

        return data as Promise<DestinationsResponse[]>;
      }
    } catch (error) {
      console.error('Fetch error: Destinations', error);
      return [];
    }
  };

  const submitForm = async (captcha: string) => {
    setFormSubmitted(true);
    let data = {};

    for (const [key, obj] of Object.entries(formData.state)) {
      data = {
        ...data,
        [key]: key === 'destination' ? String(obj.value) : obj.value,
        captcha
      };
    }

    try {
      const response = await fetch(api.create_customer_comment, {
        method: "POST",
        headers: {
          'x-api-key': api.x_keys.create_customer_comment,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      const parsedRes = await response.json().then((res: FormSubmitResponse) => res);
      if (parsedRes.status == 'success') {
        setFormSubmitSuccess(true);
        navigate(`/customercomments/createsuccess/${parsedRes.commentNumber}`);
      } else {
        setFormSubmitSuccess(false);
      }
    } catch (error) {
      console.error('Error post form', error)
    } finally {
      setFormSubmitted(false);
    }
  };

  const getBusLines = useCallback(initBusLines, []);
  const getDestinations = useCallback(initDestinations, [formData.state.busRailLine]);
  const getRailLines = useCallback(initRailLines, []);
  const handleChange = useCallback(formDataHandler, [formData]);
  const handleChangeDateTime = useCallback(formDataHandlerDateTime, [formData]);
  const handleErrors = useCallback(formErrorHandler, [isErrors]);
  const handleLanguageChange = useCallback(setLanguage, [isErrors]);
  const handleSubmitForm = useCallback(submitForm, [formData.state]);

  useEffect(() => {
    const busOrRail: string = formData.state.transitType.value;
    if (busOrRail === 'rail' && (!railLines || railLines.length === 0)) {
      const response = getRailLines();
      response
        .then((res) => setRailLines(res as RailLinesResponse[]))
        .catch(e => console.error('Error in fetch rail lines return promise', e));

    } else if (busOrRail === 'bus' && (!busLines || busLines.length === 0)) {
      const response = getBusLines();
      response
        .then((res) => setBusLines(res as BusLinesResponse[]))
        .catch(e => console.error('Error in fetch rail buses return promise', e));
    }

  }, [formData.state.busRailLine, getBusLines, getRailLines]);

  useEffect(() => {
    if (formData.state.busRailLine.value.length > 0
      && !checkDestinations(formData.state.busRailLine.value)
      && (formData.state.transitType.value === 'rail' || formData.state.transitType.value === 'bus')) {
      const response = getDestinations(String(formData.state.busRailLine.value));
      response
        .then((res: DestinationsResponse[]) => {
          const busStopDetails: DestinationsResponse[] = [];
          res.length > 0 && res.forEach((element: DestinationsResponse) => {
            return busStopDetails.push({
              ...element,
              busRailId: formData.state.busRailLine.value
            });
          });
          setDestinations({
            ...destinations,
            [formData.state.transitType.value]: [
              ...destinations[formData.state.transitType.value],
              ...busStopDetails
            ]
          });
        })
        .catch((error) => console.error('Error in set state destinations', error));
    }
  }, [formData.state.busRailLine, getDestinations]);

  const checkDestinations = (busLineStopID: number | string) => {
    if (formData.state.transitType.value.length > 0) {
      const transportation = formData.state.transitType.value;

      return destinations && destinations[transportation].find((el) => el.busRailId == busLineStopID);
    }
  };

  const contextValue = useMemo(() => ({
    busLines,
    destinations,
    ...formData,
    formSubmitSuccess,
    formSubmitted,
    isErrors,
    railLines,
    handleChange,
    handleChangeDateTime,
    handleErrors,
    handleLanguageChange,
    handleSubmitForm
  }), [
    busLines,
    destinations,
    formData,
    formSubmitSuccess,
    formSubmitted,
    railLines,
    getDestinations,
    getRailLines,
    handleChange,
    handleChangeDateTime,
    handleErrors,
    handleLanguageChange,
    handleSubmitForm
  ]);

  return (
    <CustomerCommentFormContext.Provider
      value={contextValue}
    >
      {children}
    </CustomerCommentFormContext.Provider>
  );
};

export default CustomerCommentFormProvider;

const currentHostname = window.location.hostname;
const PROD_HOST_NAME = 'ccatsform.metro.net';
const DEV_HOST_NAME = 'ccatsformdev.metro.net';
const IS_PROD = currentHostname === PROD_HOST_NAME;
const IS_DEV = currentHostname === DEV_HOST_NAME;
const dev_captcha_site_key = '6LcypqsaAAAAAOx5if2yk4neOrGqHqzefl6_staV';
const prod_captcha_site_key = '6LeL67AaAAAAABGT2gsLQfIdR6FfgCtjzUgk0mQB';
const CREATE_CUSTOMER_COMMENT_URL = IS_PROD
  ? 'https://apip.metro.net/webapps/ccats/v1/create-new-customer-comment/'
  : 'https://apit.metro.net/webapps/ccats/v1/create-new-customer-comment/';

/**
 * The alternative URL's that begin with a `/`, such as `/getraillines`,
 * are for when developing on localhost. It is a Proxy route for the URLs
 * in webpack.dev.js
 * This is to bypass CORS issues when developing on localhost
 */
const api = {
  railLines: IS_PROD || IS_DEV ? 'https://apip.metro.net/webapps/rail/v3/all-lines' : '/getraillines',
  buslines: IS_PROD || IS_DEV ? 'https://apip.metro.net/webapps/bus/v1/get-all-bus-lines' : '/getbuslines',
  create_customer_comment: IS_PROD || IS_DEV
    ? CREATE_CUSTOMER_COMMENT_URL
    : '/createcustomercomment',
  bus_stops: IS_PROD || IS_DEV ? 'https://apip.metro.net/webapps/bus/v1/get-all-bus-stops-by-line' : '/getbusstops',
  rail_stops: IS_PROD || IS_DEV ? 'https://apip.metro.net/webapps/rail/v3/all-stops-by-line' : '/getrailstops',
  bus_key: 'ee5f70b0-bd7c-4b3b-a869-bf95cd2fab83',
  rail_key: 'a32aab12-0420-45d4-9822-c075a70d0438',
  captcha: {
    site_key: IS_PROD ? prod_captcha_site_key : dev_captcha_site_key
  },
  x_keys: {
    create_customer_comment: IS_PROD ? '2abed2ba-9fb0-41fe-80ea-7ba103c4b66d' : 'd9ce4582-cf38-40b3-94c7-1fc9557d9fff'
  }
};

export default api;

export interface NavbarTextObject {
  isReactRoute: boolean;
  text: {
    [key: string]: string;
  }
  url: string;
}

interface NavbarText {
  links: NavbarTextObject[]
}

const navbarText: NavbarText = {
  links: [
    {
      isReactRoute: false,
      text: {
        en: "Metro Home",
        es: "Metro Inicio",
        hy: "Metro տուն",
        km: "ទំព័រដើម Metro",
        ko: "메트로 홈",
        zh: "Metro 首頁",
        ja: "Metro ホーム",
        ru: "Главная страница",
        th: "หน้าแรก",
        vi: "Trang chủ Metro"
      },
      url: "https://www.metro.net/"
    },
    {
      isReactRoute: false,
      text: {
        en: "Civil Rights",
        es: "Derechos Civiles",
        hy: "Քաղաքացիական իրավունքներ",
        km: "សិទ្ធិជនស៊ីវីល",
        ko: "시민의 권리",
        zh: "公民權",
        ja: "公民権",
        ru: "Гражданские права",
        th: "สิทธิมนุษยชน",
        vi: "Quyền Công Dân"
      },
      url: "https://www.metro.net/about/civil-rights-policy/"
    }
  ]
};

export default navbarText;

import React, { Fragment } from 'react';
import { Outlet } from "react-router-dom";
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { MainTheme } from "ts_mui_modules";
import { Footer, Navbar } from 'components';
import { CustomerCommentFormProvider } from 'context';
import api from '../../../api';

export const CAPTCHA_COMPONENT_ID: string = 'react-captcha-container';

const App: React.FC = (): JSX.Element => {
  return (
    <Fragment>
      {/* CssBaseline - Needed for applying global styles to MUI components defined in mainTheme */}
      <CssBaseline />
      <CustomerCommentFormProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={api.captcha.site_key}
          // language={}
          scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'body', // optional, default to "head", can be "head" or "body",
            nonce: undefined // optional, default undefined
          }}
          container={{ // optional to render inside custom element
            element: CAPTCHA_COMPONENT_ID,
            parameters: {
              badge: 'bottomleft', // optional, default undefined
              theme: 'dark', // optional, default undefined
            }
          }}
        >
          <ThemeProvider theme={MainTheme}>
            <Navbar />
            <Outlet />
            <Box id={CAPTCHA_COMPONENT_ID} margin='normal' sx={{ display: 'flex', justifyContent: 'flex-end' }} />
            <Footer />
          </ThemeProvider>
        </GoogleReCaptchaProvider>
      </CustomerCommentFormProvider>
    </Fragment >
  );
};

export default App;

import React from 'react';
import { AppBar, Box, Container, Divider, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <AppBar
      sx={{
        backgroundColor: theme.custom.white,
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: 0,
        marginTop: '20px'
      }}
      position='sticky'
    >
      <Divider />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: theme.custom.black,
          borderWidth: 'thin',      
          maxWidth: {
            xs: theme.breakpoints.values['sm'],
            md: theme.breakpoints.values['md'],
            lg: '1235px',
          },
          minHeight: {
            xs: '50px',
            md: '72px'
          }
        }}
      >
        <Box component='div'>
          <Typography sx={{ color: theme.custom.black }} variant='body2'>Copyright &copy; {dayjs().year()} Metro</Typography>
        </Box>
      </Container>
    </AppBar>
  );
};

export default Footer;

import React, { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  useTheme,
} from '@mui/material';
import {
  LinkComponent
} from 'components';
import customerFormText from 'containers/customer_comment_form/text';
import { CustomerCommentFormContext } from 'context';
import customerFormStyles from 'containers/customer_comment_form/styles';
import { Box } from '@mui/system';

const SubmitSuccessPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { formSubmitSuccess, ...formData } = useContext(CustomerCommentFormContext);
  const language = formData.state.language.value;
  const theme = useTheme();
  const { comment_id } = useParams();
  const {
    submitSuccessPage
  } = customerFormText;

  useEffect(() => {
    if (!formSubmitSuccess) {
      navigate('/');
    }
  }, [formSubmitSuccess]);

  return (
    <Container>
      <Typography
        sx={() => customerFormStyles(theme).headers.pageTitle}
        gutterBottom
        borderBottom={1 / 2}
      >
        {submitSuccessPage[language].title}
      </Typography>
      <Typography
        gutterBottom
        variant='body2'
      >
        {submitSuccessPage[language].p1}
      </Typography>
      <Typography
        gutterBottom
        variant='body2'
      >
        {submitSuccessPage[language].p2.text}
        <Typography
          variant='body2'
          component={`span`}
          sx={() => customerFormStyles(theme).formSubmtSuccess.fontColor}

        >
          {submitSuccessPage[language].p2.comment}{comment_id}
        </Typography>
        <Typography
          variant='body2'
          component={`span`}
        >
          {submitSuccessPage[language].p2.closingTxt}
        </Typography>
      </Typography>
      <Typography
        gutterBottom
        variant='body2'
      >
        {submitSuccessPage[language].p3}
      </Typography>
      <Typography
        gutterBottom
        variant='body2'
      >
        {submitSuccessPage[language].p4}
      </Typography>
      <Box sx={() => customerFormStyles(theme).formSubmtSuccess.contactInfo}>
        <Box>
          <Typography
            sx={{ m: 0 }}
            variant='body2'
          >
            <b>{submitSuccessPage['en']?.contact?.title}</b>
          </Typography>
          <Typography
            sx={{ m: 0 }}
            variant='body2'
          >
            {submitSuccessPage['en']?.contact?.building}
          </Typography>
          <Typography
            sx={{ m: 0 }}
            variant='body2'
          >
            {submitSuccessPage['en']?.contact?.address}
          </Typography>
          <Typography
            sx={{ m: 0 }}
            variant='body2'
          >
            <LinkComponent
              isReactRoute={false}
              url={submitSuccessPage['en']?.contact?.link || ""}
            >
              {submitSuccessPage['en']?.contact?.link.replace('mailto:', '')}
            </LinkComponent>
          </Typography>
          <Typography
            sx={{ m: 0 }}
            variant='body2'
          >
            {submitSuccessPage['en']?.contact?.phone}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default SubmitSuccessPage;

import React, { useContext } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { INPUT_TYPE, FormInputsObject } from 'containers/customer_comment_form/text';
import { CustomerCommentFormContext } from 'context';
import { PhoneMaskInput } from 'components';
import { checkValidEmailPattern } from 'helpers';

interface InputsProps {
  form: FormInputsObject;
}

const Inputs: React.FC<InputsProps> = ({ form }) => {
  const formData = useContext(CustomerCommentFormContext);
  const formObject = formData?.state[form.stateKey];
  const language = formData.state.language.value;

  if (form.inputType === INPUT_TYPE.select) {
    return (
      <TextField
        select
        error={(formData.isErrors && formObject?.value.length === 0 && formObject.required)}
        fullWidth={true}
        helperText={(formData.isErrors && formObject?.value.length === 0 && formObject.required) ? 'This field is required' : ''}
        id={form?.label && form.label[language]}
        label={form?.label && form.label[language]}
        margin='dense'
        onChange={formData?.handleChange ? formData.handleChange(form.stateKey) : undefined}
        size='small'
        value={formObject?.value}
      >
        {form?.dropDownItems && form?.dropDownItems.map((item, i) => (
          <MenuItem key={`select-${form?.label && form.label[language]}-${item.label}-${i}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  if (form.inputType === INPUT_TYPE.radio) {
    return (
      <FormControl
        fullWidth={true}
        margin='dense'
        error={(formData.isErrors && formObject?.value.length === 0 && formObject.required)}
      >
        <FormLabel id={form.label && form.label[language]}>{form?.label && form.label[language]}</FormLabel>
        <RadioGroup
          onChange={formData?.handleChange ? formData.handleChange(form.stateKey) : undefined}
          value={formObject?.value}
          row
        >
          {form?.radioGroupItems && form?.radioGroupItems.map((item, i) => (
            <FormControlLabel
              key={`radio-input-${item.label[language]}-${i}`}
              value={item.value}
              control={<Radio />}
              label={item.label[language]}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  if (form.inputType === INPUT_TYPE.date) {
    return (
      <FormControl
        fullWidth={true}
        margin='dense'
        error={(formData.isErrors && formObject?.value.length === 0 && formObject.required)}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            defaultValue={dayjs(formObject.value)}
            value={dayjs(formObject.value)}
            label={form?.label && form.label[language]}
            slotProps={{ textField: { size: 'small' } }}
            onChange={(date) =>
              formData.handleChangeDateTime && date ?
                formData.handleChangeDateTime(form.stateKey, dayjs(date.local().format())) :
                undefined
            }
          />
        </LocalizationProvider>
      </FormControl>
    );
  }

  if (form.inputType === INPUT_TYPE.time) {
    return (
      <FormControl
        fullWidth={true}
        margin='dense'
        error={(formData.isErrors && formObject?.value.length === 0 && formObject.required)}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            defaultValue={dayjs(formObject.value)}
            value={dayjs(formObject.value)}
            label={form?.label && form.label[language]}
            slotProps={{ textField: { size: 'small' } }}
            onChange={(date) =>
              formData.handleChangeDateTime && date ?
                formData.handleChangeDateTime(form.stateKey, dayjs(date.local().format())) :
                undefined}
          />
        </LocalizationProvider>
      </FormControl>
    );
  }

  if (form.inputType === INPUT_TYPE.textarea) {
    return (
      <TextField
        multiline
        error={(formData.isErrors && formObject?.value.length === 0 && formObject.required)}
        helperText={(formData.isErrors && formObject?.value.length === 0 && formObject.required) ? 'This field is required' : ''}
        rows={5}
        fullWidth={true}
        id={form?.label && form.label[language]}
        label={form?.label && form.label[language]}
        margin='dense'
        onChange={formData?.handleChange ? formData.handleChange(form.stateKey) : undefined}
        type={form?.type && form.type}
        value={formObject?.value}
        variant='outlined'
        inputProps={{ maxLength: form?.maxLength && form.maxLength }}
      />
    );
  }

  if (form.inputType === INPUT_TYPE.input) {
    return (
      <TextField
        error={
          (formData.isErrors && formObject?.value.length === 0 && formObject.required) ||
          (form.stateKey === 'email' && formObject?.value.length > 0 && !checkValidEmailPattern(formObject?.value))
        }
        helperText={
          (formData.isErrors && formObject?.value.length === 0 && formObject.required) ? 'This field is required' : '' ||
          (form.stateKey === 'email' && formObject?.value.length > 0 && !checkValidEmailPattern(formObject?.value) ? 'Invalid Email' : '')
        }
        fullWidth={true}
        id={form?.label && form.label[language]}
        label={form?.label && form.label[language]}
        margin='dense'
        onChange={formData?.handleChange ? formData.handleChange(form.stateKey) : undefined}
        size='small'
        type={form?.type && form.type}
        value={formObject?.value}
        variant='outlined'
        inputProps={{ maxLength: form?.maxLength && form.maxLength }}
      />
    );
  }

  if (form.inputType === INPUT_TYPE.phone) {
    return (
      <FormControl
        error={(formData.isErrors && formObject?.value.length === 0 && formObject.required)}
        fullWidth={true}
        margin='dense'
        size='small'
        variant='outlined'
      >
        <OutlinedInput
          id={form?.label && form.label[language]}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          inputComponent={PhoneMaskInput as any}
          onChange={formData?.handleChange ? formData.handleChange(form.stateKey) : undefined}
          type={form?.type && form.type}
          value={formObject?.value}
          placeholder={form?.label && form.label[language]}
        />
        {
          (formData.isErrors && formObject?.value.length === 0 && formObject.required) ? (
            <FormHelperText
              error={(formData.isErrors && formObject?.value.length === 0 && formObject.required)}
            >
              This field is required
            </FormHelperText>
          ) : null
        }
      </FormControl>
    );
  }
};

export default Inputs;

import { PaletteOptions } from '@mui/material';

/**
 * This is for MUI node module when using TypeScript.
 * Required if adding or altering props and properties for a MUI component
 * 
 * This module is for adding a property "custom" to the MUI Pallete object
 * that is exposed with the MUI theme (createTheme())
 */
interface CustomPallete {
  black: string
  white: string
}

const customPallete: CustomPallete = {
  black: '#262626',
  white: '#FFFFFF'
};

interface CustomColorPalette {
  custom: CustomPallete
}

declare module '@mui/material/styles' {
  interface Palette extends CustomColorPalette {}
  interface PaletteOptions extends CustomColorPalette {}
}

const MainPalette: PaletteOptions = {
  custom: customPallete
};

export default MainPalette;

import { createTheme } from '@mui/material';
import { MainPalette } from "ts_mui_modules";
import OpenSans from 'fonts/static/OpenSans-Regular.ttf';

/**
 * This is for MUI node module when using TypeScript.
 * Required if adding or altering props and properties for a MUI component
 * 
 * This module is for defining general and shared themes (styles) used throughout
 * app
 */

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      black: string
      white: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom: {
      black: string
      white: string
    }
  }
}

// Global CSS / Normalized CSS
const MainTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        div, h1, h2, h3, h4, h5, h6, p, ol, ul, li, span { 
          margin: 0;
        }
        @font-face {
          font-family: 'Open Sans', 'Sans-serif;
          font-style: normal;
          font-weight: 400;
          src: local('Open Sans'), local('Sans-serif'), url(${OpenSans}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
  ...MainPalette,
  typography: {
    h4: {
      marginTop: 15,
      marginBottom: 15
    },
    h5: {
      marginTop: 15,
      marginBottom: 15
    },
    h6: {
      marginTop: 15,
      marginBottom: 15
    },
    body2: {
      marginTop: 15,
      marginBottom: 15
    }
  },
});

export default MainTheme;

// The following Typescript interfaces are related to this text file.
// The text file serves as an object with the content that will be
// displayed on the front end

interface INPUT_TYPES_T {
  date: string;
  input: string;
  phone: string;
  radio: string;
  select: string;
  textarea: string;
  time: string;
}

export const INPUT_TYPE: INPUT_TYPES_T = {
  date: 'date',
  input: 'input',
  phone: 'phone',
  radio: 'radio',
  select: 'select',
  textarea: 'textarea',
  time: 'time'
};

export type DropdownItems = {
  label?: string;
  value?: string | number;
}

export type RadioGroup = {
  label: {
    [key: string]: string;
  }
  value: string;
}

export type FormInputsObject = {
  inputType: string;
  dropDownItems?: DropdownItems[];
  radioGroupItems?: RadioGroup[];
  label?: {
    [key: string]: string;
  }
  maxLength?: number;
  required: boolean;
  stateKey: string;
  title?: {
    [key: string]: string;
  }
  type?: string;
}

type paragraphObject = {
  [langauge: string]: {
    text: string;
    link: string;
    afterLinkText?: string;
  }
}

type radioGroupObject = {
  title: {
    [key: string]: string;
  }
  transitType: FormInputsObject;
}

interface SubmitSuccessPage {
  [language: string]: {
    title: string;
    p1: string;
    p2: {
      text: string;
      comment: string;
      closingTxt?: string;
    };
    p3: string;
    p4: string;
    contact?: {
      link: string;
      [key: string]: string;
    }
  }
}

interface CustomerCommentFormTypes {
  mainTitle: {
    [key: string]: string;
  }
  paragraphs: {
    p1: paragraphObject;
    p2: paragraphObject;
    p3: paragraphObject;
    p4: paragraphObject;
  },
  personalInfoSection: FormInputsObject[];
  radioGroupSection: radioGroupObject;
  reportForm: {
    boardingLocation: FormInputsObject;
    busNumber: FormInputsObject;
    carNumber: FormInputsObject;
    description: FormInputsObject;
    incidentDate: FormInputsObject;
    incidentTime: FormInputsObject;
    operatorBadgeNumber: FormInputsObject;
    operatorDescription: FormInputsObject;
    microTransitZone: FormInputsObject;
    bikeShareStation: FormInputsObject;
    tapCard: FormInputsObject;
    generalCommentsIncidentLocation: FormInputsObject;
    generalCommentsEmployeeDescription: FormInputsObject;
    generalCommentsLicensePlateNumber: FormInputsObject;
    generalCommentsVehicleID: FormInputsObject;
    followUp: FormInputsObject;
  },
  submitSuccessPage: SubmitSuccessPage
}

const formText: CustomerCommentFormTypes = {
  mainTitle: {
    en: 'Customer Comment Form',
    es: 'Formulario de comentarios del cliente',
    hy: 'Հաճախորդի մեկնաբանման ձեւ',
    km: 'សំណុំបែបបទមតិយោបល់អតិថិជន',
    ko: '고객 의견서',
    zh: '乘客意見表',
    ja: '顧客コメントフォーム',
    ru: 'Форма подачи комментариев',
    th: 'แบบฟอร์มแสดงความเห็นสำหรับลูกค้า',
    vi: 'Mẫu nhận xét khách hàng'
  },
  paragraphs: {
    p1: {
      en: {
        text: 'Thank you for taking time to share your experience aboard Metro Bus or Rail with us. \
          Your input helps us provide better service. Your report will be reviewed by \
          Customer Relations staff and given appropriate management follow-up.',
        link: ''
      },
      es: {
        text: 'Gracias por tomarse el tiempo para compartirnos su experiencia a bordo de los autobuses\
          o trenes de Metro con nosotros. Su opinión nos ayuda a brindar un mejor servicio. \
          Su informe será revisado por el personal de Relaciones con los Clientes y se le dará el seguimiento adecuado.',
        link: ''
      },
      hy: {
        text: 'Շնորհակալություն, որ ժամանակ եք հատկացնում Metro-ի ավտոբուսով կամ գնացքով երթևեկելու \
          մասին Ձեր փորձառությունը հայտնելու համար։ Ձեր կարծիքը օգնում է մեզ ավելի լավ ծառայություններ \
          մատուցելու հարցում։ Ձեր տեղեկությունները կուսումնասիրվեն Հաճախորդների սպասարկման կենտրոնի \
          աշխատակցի կողմից, և ղեկավարությանը կտրվեն համապատասխան հանձնարարականներ։',
        link: ''
      },
      km: {
        text: 'សូមអរគុណលោកអ្នកចំពោះការចំណាយពេលវេលា ដើម្បីចែករំលែកបទពិសោធន៍របស់អ្នកនៅលើរថយន្ដក្រុង Metro \
          ឫរថភ្លើងជាមួយយើងខ្ញុំ។ តម្រូវការរបស់អ្នក ជួយយើងខ្ញុំឱ្យផ្តល់សេវាកម្មកាន់តែល្អប្រសើរ។ \
          របាយការណ៍របស់អ្នកនឹងត្រូវពិនិត្យឡើងវិញដោយបុគ្គលិកទំនាក់ទំនងអតិថិជន និងផ្តល់ការតាមដានលើការគ្រប់គ្រងសមស្រប។',
        link: ''
      },
      ko: {
        text: '메트로 버스 또는 지하철에 대한 귀하의 경험을 저희와 공유해주시는 데 감사드립니다. 귀하의 의견은 저희가 \
          더 나은 서비스를 제공하도록 하는 데에 도움을 줄 것입니다. 귀하의 보고는 고객 관계 직원에 의해 검토될 것이며 \
          적절하게 관리자에 의해 처리 될 것입니다.',
        link: ''
      },
      zh: {
        text: '感謝您撥空與我們分享搭乘Metro公車或鐵路的經驗。您的意見幫助我們提供更完善的服務。客戶關係人員將審閱您的報告並提供適當後續追蹤。',
        link: ''
      },
      ja: {
        text: '私共とあなたのMetro BusまたはRailの乗車の経験について時間をとってシェアして頂きありがとうございます。\
          あなたの情報のインプットは私ともが良いサービスを提供するために役立ちます。 \
          あなたのレポートはカスタマーリレーションズのスタッフによって検討されマネージメントによる適切なフォローアップが行われます。',
        link: ''
      },
      ru: {
        text: 'Благодарим вас за то, что уделили время и рассказали нам, что вы думаете о поездках в автобусах и поездах Metro. \
          Ваше мнение поможет нам совершенствовать наш сервис. Ваш отзыв будет рассмотрен сотрудником отдела по работе \
          с клиентами и направлен соответствующему руководителю.',
        link: ''
      },
      th: {
        text: 'ขอบคุณที่สละเวลาแบ่งปันประสบการณ์การโดยสาร Metro Bus หรือ Rail กับเรา ความเห็นของคุณช่วยเราปรับปรุงบริการ \
          เจ้าหน้าที่ฝ่ายลูกค้าสัมพันธ์จะตรวจสอบรายงานของคุณและทำการติดตามผลอย่างเหมาะสม',
        link: ''
      },
      vi: {
        text: 'Cảm ơn bạn đã dành thời gian chia sẻ kinh nghiệm của bạn khi đi Xe Buýt Metro hay Tàu Điện với chúng tôi. \
          Ý kiến đóng góp của bạn giúp chúng tôi cung cấp dịch vụ tốt hơn. Tường trình của bạn sẽ được nhân viên Quan Hệ \
          Khách Hàng xem lại và được quản lý theo dõi thích hợp.',
        link: ''
      },
    },
    p2: {
      en: {
        text: 'Note: Please use this form to report your experience with Metro services. \
          If you would like to request timetables, maps, or brochures, \
          e-mail us at: ',
        link: 'mailto:CustomerRelations@Metro.net'
      },
      es: {
        text: 'Nota: por favor, utilice este formulario para informarnos sobre su experiencia con \
          los servicios de Metro. Si desea solicitar horarios, mapas o folletos, envíenos un correo electrónico a: ',
        link: 'mailto:CustomerRelations@Metro.net'
      },
      hy: {
        text: 'Ծանուցում. Խնդրում ենք օգտագործել այս ձևաթուղթը՝ Metro-ի ծառայությունների վերաբերյալ Ձեր փորձառությունը \
          հայտնելու համար։ Եթե ցանկանում եք ժամացուցակներ, քարտեզներ կամ գրքույկներ խնդրել, մեզ էլ. նամակ ուղարկեք ',
        link: 'mailto:CustomerRelations@Metro.net',
        afterLinkText: ' հասցեով։'
      },
      km: {
        text: 'កំណត់សម្គាល់៖ សូមប្រើសំណុំបែបបទនេះ ដើម្បីរាយការណ៍អំពីបទពិសោធន៍របស់អ្នកជាមួយសេវាកម្ម Metro ។ \
          ប្រសិនបើលោកអ្នកចង់ស្នើសុំតារាងពេលវេលាឬតារាងកម្មវិធី ផែនទី ឬខិត្តប័ណ្ណ សូមផ្ញើអ៊ីម៉ែលមកយើងខ្ញុំតាម៖ ',
        link: 'mailto:CustomerRelations@Metro.net'
      },
      ko: {
        text: '비고: 이 양식을 사용하여 귀하의 경험을 메트로 서비스에 보고해주십시오. 시간표, 지도 또는 브로셔를 요청하시려면: ',
        link: 'mailto:CustomerRelations@Metro.net',
        afterLinkText: ' 로 이메일 주십시오.'
      },
      zh: {
        text: '備註：請使用本表報告您使用Metro服務的經驗。若欲索取時刻表、地圖或手冊，請寄電子郵件至：',
        link: 'mailto:CustomerRelations@Metro.net'
      },
      ja: {
        text: '注:このフォームを使ってMetroサービスでのあなたの経験をレポートしてください。もしタイムテーブル、\
          地図、案内書等をリクエストしたい場合には以下の: ',
        link: 'mailto:CustomerRelations@Metro.net',
        afterLinkText: ' までEmailをお送りください。'
      },
      ru: {
        text: 'Учтите: Просим вас пользоваться этой формой для отзывов об услугах Metro. Если вам нужны \
          расписания, карты или брошюры, cообщите нам по электронной почте: ',
        link: 'mailto:CustomerRelations@Metro.net'
      },
      th: {
        text: 'หมายเหตุ: โปรดใช้แบบฟอร์มนี้รายงานประสบการณ์ของคุณกับฝ่ายบริการ Metro หากคุณต้องการขอตารางเดินรถ \
          แผนที่หรือหนังสือแนะนำต่างๆ โปรดส่งอีเมลถึงเราที่: ',
        link: 'mailto:CustomerRelations@Metro.net'
      },
      vi: {
        text: 'Lưu ý: Vui lòng sử dụng mẫu này để tường trình kinh nghiệm của bạn với dịch vụ Metro. \
          Nếu bạn muốn yêu cầu thời gian biểu, bản đồ, hoặc các tờ quảng cáo, vui lòng e-mail cho chúng tôi tại: ',
        link: 'mailto:CustomerRelations@Metro.net'
      }
    },
    p3: {
      en: {
        text: 'If your comments are lengthy or complex, we urge you to use e-mail rather than this\
         form or to contact a Metro Passenger Relations Representative at 213.922.6235 or 1.800.464.2111',
        link: ''
      },
      es: {
        text: 'Si sus comentarios son extensos o complejos, le aconsejamos enviarlos por correo electrónico \
          en lugar de usar este formulario, o comunicarse con un Representante de Relaciones con los Pasajeros \
          de Metro, llamando al 213.922.6235 o al 1.800.464.2111',
        link: ''
      },
      hy: {
        text: 'Եթե Ձեր մեկնաբանություններն երկար և բարդ են, մենք խրախուսում ենք այս ձևաթղթի փոխարեն էլ. \
          նամակ օգտագործել կամ կապվել Metro-ի Ուղևորնեի հետ կապի բաժնի ներկայացուցչի հետ՝ 213,922.6235 \
          կամ 1.800.464,2111 հեռախոսահամարով։',
        link: ''
      },
      km: {
        text: 'ប្រសិនបើមតិយោបល់របស់អ្នកវែង ឬស្មុគស្មាញ យើងខ្ញុំលើកទឹកចិត្តលោកអ្នកឱ្យប្រើអ៊ីម៉ែលជាជាងសំណុំបែបបទនេះ \
          ឬទាក់ទងអ្នកតំណាងទំនាក់ទំនងអ្នកដំណើរតាម Metro តាមលេខ 213,922,6235 ឬ 1,800,464,2111',
        link: ''
      },
      ko: {
        text: '귀하의 의견이 길거나 복잡할 경우, 이 양식보다는 이메일로 연락을 주시거나 또는 메트로 승객 \
          관계 담당인에게 213,922.6235 또는 1.800.464.2111으로 전화주시기 바랍니다.',
        link: ''
      },
      zh: {
        text: '若您的意見冗長或複雜，我們建議您改用電子郵件或聯絡Metro客戶關係代表213.922.6235或1.800.464.2111',
        link: ''
      },
      ja: {
        text: 'もしあなたのコメントが大変長かったり複雑な場合にはこのフォームを使うよりもEmailを使うかもしあなたのコメントが大変長かったり\
          複雑な場合にはこのフォームを使うよりもEmailを使うかMetro Passenger Relations Representative 213,922.6235 または 1.800.464,2111に電話連絡することを強くお勧めします。',
        link: ''
      },
      ru: {
        text: 'Если ваш отзыв достаточно большой или сложный, не пользуйтесь формой и напишите нам по электронной \
          почте или позвоните сотруднику отдела по работе с пассажирами Metro по телефону 213.922.6235 или 1.800.464.2111',
        link: ''
      },
      th: {
        text: 'หากความเห็นของคุณยาวหรือซับซ้อน ขอแนะนำให้คุณใช้อีเมลแทนแบบฟอร์มนี้หรือติดต่อตัวแทนฝ่ายผู้โดยสารสัมพันธ์ \
          Metro ที่ 213.922.6235 หรือ 1.800.464.2111',
        link: ''
      },
      vi: {
        text: 'Nếu ý kiến của bạn dài hay phức tạp, chúng tôi đề nghị bạn sử dụng e-mail hơn là sử dụng mẫu này, \
          hãy liên hệ với Đại Diện Quan Hệ Khách Hàng của Metro theo số 213.922.6235 hoặc 1.800.464, 2111',
        link: ''
      }
    },
    p4: {
      en: {
        text: 'Information you provide us on this website / form may be subject to disclosure under the\
         California Public Records Act.',
        link: ''
      },
      es: {
        text: 'La información que nos proporcione en este sitio web/formulario podría estar sujeta a su \
          divulgación, bajo la Ley de Registros Públicos de California.',
        link: ''
      },
      hy: {
        text: 'Այս կայքում/ձևաթղթում տրամադրված տեղեկությունները կարող են բացահայտվել Կալիֆորնիայի Հանրային \
          գրառումների մասին օրենքի հիման վրա։',
        link: ''
      },
      km: {
        text: 'ព័ត៌មានដែលលោកអ្នកផ្តល់ជូនយើងខ្ញុំតាមវិបសាយ/សំណុំបែបបទនេះ \
          អាចកំណត់ការបញ្ចេញឱ្យដឹងព័ត៌មាននៅក្រោមច្បាប់ស្តីពីការធ្វើកំណត់ត្រាសាធារណៈនៅរដ្ឋកាលីហ្វ័រញ៉ា។',
        link: ''
      },
      ko: {
        text: '이 웹사이트에서 저희에게 제공해주신 정보는 캘리포니아 공공 기록법 하에 공개가 될 수 있습니다.',
        link: ''
      },
      zh: {
        text: '您在本網站/本表提供的資料須遵守加州公共紀錄法案的揭露規定。',
        link: ''
      },
      ja: {
        text: 'あなたがこのウエブサイト提供した情報は California Public Records Act.\
          の法律にした従いまして公開される可能性があるかもしれません。',
        link: ''
      },
      ru: {
        text: 'Информация,указанные вами в этой форме, могут быть раскрыты третьим лицам согласно Закону о \
          публичном доступе к информации в штате Калифорния.',
        link: ''
      },
      th: {
        text: 'ข้อมูลที่คุณส่งให้เราบนเว็บไซต์หรือแบบฟอร์มนี้อาจต้องถูกเปิดเผยตามกฎหมายบันทึกสาธารณะแคลิฟอร์เนีย',
        link: ''
      },
      vi: {
        text: 'Thông tin bạn cung cấp cho chúng tôi trên trang web/mẫu này có thể bị tiết lộ theo Đạo \
          Luật Hồ Sơ Công Cộng Bang California',
        link: ''
      }
    }
  },
  personalInfoSection: [
    {
      inputType: INPUT_TYPE.select,
      dropDownItems: [
        {
          label: 'English',
          value: 'en'
        },
        {
          label: 'Espanol',
          value: 'es'
        },
        {
          label: 'հայերեն',
          value: 'hy'
        },
        {
          label: 'ខ្មែរ',
          value: 'km'
        },
        {
          label: '한국어',
          value: 'ko'
        },
        {
          label: '中文',
          value: 'zh'
        },
        {
          label: '日本語',
          value: 'ja'
        },
        {
          label: 'Русский',
          value: 'ru'
        },
        {
          label: 'ไทย',
          value: 'th'
        },
        {
          label: 'Tiếng Việt',
          value: 'vi'
        }
      ],
      label: {
        en: 'Language',
        es: 'Idioma',
        hy: 'Լեզու',
        km: 'ភាសា',
        ko: '언어',
        zh: '語言',
        ja: '言語',
        ru: 'Язык',
        th: 'ภาษา',
        vi: 'Ngôn ngữ'
      },
      required: false,
      stateKey: 'language',
      title: {
        en: 'Language Selection',
        es: 'Selección de idioma',
        hy: 'Լեզվի ընտրություն',
        km: 'ការជ្រើសរើសភាសា',
        ko: '언어 선택',
        zh: '語言選擇',
        ja: '言語選択',
        ru: 'Выбор языка',
        th: 'การเลือกภาษา',
        vi: 'Sự lựa chọn ngôn ngữ'
      }
    },
    {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'First Name*',
        es: 'Primer nombre*',
        hy: 'Անուն*',
        km: 'នាមខ្លួន*',
        ko: '이름*',
        zh: '名*',
        ja: '名前*',
        ru: 'Имя*',
        th: 'ชื่อ*',
        vi: 'Tên*'
      },
      maxLength: 50,
      required: true,
      stateKey: 'firstName',
      title: {
        en: 'Personal Information',
        es: 'Información personal',
        hy: 'Անձնական տեղեկություններ',
        km: 'ព័ត៌មានផ្ទាល់ខ្លួន',
        ko: '개인 정보',
        zh: '個人資料',
        ja: '個人情報',
        ru: 'Персональные информация',
        th: 'ข้อมูลส่วนตัว',
        vi: 'Thông tin cá nhân'
      }
    },
    {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'Last Name*',
        es: 'Apellido*',
        hy: 'Ազգանուն*',
        km: 'នាមត្រកូល*',
        ko: '성*',
        zh: '姓*',
        ja: '姓*',
        ru: 'Фамилия*',
        th: 'นามสกุล*',
        vi: 'Họ*'
      },
      maxLength: 50,
      required: true,
      stateKey: 'lastName'
    },
    {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'Email Address',
        es: 'Correo electrónico',
        hy: 'Էլ. հասցե',
        km: 'អាសយដ្ឋានអ៊ីម៉ែល',
        ko: '이메일 주소',
        zh: '電子郵件地址',
        ja: 'Email アドレス',
        ru: 'Адрес эл. почты',
        th: 'ที่อยู่อีเมล',
        vi: 'Địa chỉ email'
      },
      maxLength: 255,
      required: false,
      stateKey: 'email',
      type: 'email'
    },
    {
      inputType: INPUT_TYPE.phone,
      label: {
        en: 'Daytime Phone*',
        es: 'Teléfono diurno*',
        hy: 'Ցերեկային հեռախոս*',
        km: 'លេខទូរស័ព្ទពេលថ្ងៃ*',
        ko: '낮시간 전화*',
        zh: '日間電話*',
        ja: '日中の電話番号*',
        ru: 'Телефон для связи в дневное время*',
        th: 'เบอร์โทรระหว่างวัน*',
        vi: 'Điện thoại ban ngày*'
      },
      required: true,
      stateKey: 'dayPhone',
      type: 'tel'
    },
    {
      inputType: INPUT_TYPE.phone,
      label: {
        en: 'Evening Phone',
        es: 'Teléfono nocturno',
        hy: 'Երեկոյան հեռախոս',
        km: 'លេខទូរស័ព្ទពេលល្ងាច',
        ko: '저녁시간 전화',
        zh: '夜間電話',
        ja: '夕方の電話番号',
        ru: 'Телефон для связи в вечернее время',
        th: 'เบอร์โทรช่วงเย็น',
        vi: 'Điện thoại ban đêm'
      },
      required: false,
      stateKey: 'eveningPhone',
      type: 'tel'
    },
    {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'Address1*',
        es: 'Dirección*',
        hy: 'Հասցե 1*',
        km: 'អាសយដ្ឋាន 1*',
        ko: '주소 1*',
        zh: '地址 1*',
        ja: '住所 1*',
        ru: 'Адрес (строка 1)*',
        th: 'ที่อยู่ 1*',
        vi: 'Địa chỉ 1*'
      },
      maxLength: 100,
      required: true,
      stateKey: 'address1'
    },
    {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'Address2',
        es: 'Dirección (Cont.)',
        hy: 'Հասցե 2',
        km: 'អាសយដ្ឋាន 2',
        ko: '주소 2',
        zh: '地址 2',
        ja: '住所 2',
        ru: 'Адрес (строка 2)',
        th: 'ที่อยู่ 2',
        vi: 'Địa chỉ 2'
      },
      maxLength: 100,
      required: false,
      stateKey: 'address2'
    },
    {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'City*',
        es: 'Ciudad*',
        hy: 'Քաղաք*',
        km: 'ទីក្រុង*',
        ko: '도시*',
        zh: '市*',
        ja: 'シティ*',
        ru: 'Город*',
        th: 'เมือง*',
        vi: 'Thành phố*'
      },
      maxLength: 30,
      required: true,
      stateKey: 'city'
    },
    {
      inputType: INPUT_TYPE.select,
      dropDownItems: [
        {
          label: 'Alabama',
          value: 'AL'
        },
        {
          label: 'Alaska',
          value: 'AK'
        },
        {
          label: 'Arizona',
          value: 'AZ'
        },
        {
          label: 'Arkansas',
          value: 'AR'
        },
        {
          label: 'California',
          value: 'CA'
        },
        {
          label: 'Colorado',
          value: 'CO'
        },
        {
          label: 'Connecticut',
          value: 'CT'
        },
        {
          label: 'Delaware',
          value: 'DE'
        },
        {
          label: 'District of Columbia',
          value: 'DC'
        },
        {
          label: 'Florida',
          value: 'FL'
        },
        {
          label: 'Georgia',
          value: 'GA'
        },
        {
          label: 'Hawaii',
          value: 'HI'
        },
        {
          label: 'Idaho',
          value: 'ID'
        },
        {
          label: 'Illinois',
          value: 'IL'
        },
        {
          label: 'Indiana',
          value: 'IN'
        },
        {
          label: 'Iowa',
          value: 'IA'
        },
        {
          label: 'Kansas',
          value: 'KS'
        },
        {
          label: 'Kentucky',
          value: 'KY'
        },
        {
          label: 'Louisiana',
          value: 'LA'
        },
        {
          label: 'Maine',
          value: 'ME'
        },
        {
          label: 'Maryland',
          value: 'MD'
        },
        {
          label: 'Massachusetts',
          value: 'MA'
        },
        {
          label: 'Michigan',
          value: 'MI'
        },
        {
          label: 'Minnesota',
          value: 'MN'
        },
        {
          label: 'Mississippi',
          value: 'MS'
        },
        {
          label: 'Missouri',
          value: 'MO'
        },
        {
          label: 'Montana',
          value: 'MT'
        },
        {
          label: 'Nebraska',
          value: 'NE'
        },
        {
          label: 'Nevada',
          value: 'NV'
        },
        {
          label: 'New Hampshire',
          value: 'NH'
        },
        {
          label: 'New Jersey',
          value: 'NJ'
        },
        {
          label: 'New Mexico',
          value: 'NM'
        },
        {
          label: 'New York',
          value: 'NY'
        },
        {
          label: 'North Carolina',
          value: 'NC'
        },
        {
          label: 'North Dakota',
          value: 'ND'
        },
        {
          label: 'Ohio',
          value: 'OH'
        },
        {
          label: 'Oklahoma',
          value: 'OK'
        },
        {
          label: 'Oregon',
          value: 'OR'
        },
        {
          label: 'Pennsylvania',
          value: 'PA'
        },
        {
          label: 'Rhode Island',
          value: 'RI'
        },
        {
          label: 'South Carolina',
          value: 'SC'
        },
        {
          label: 'South Dakota',
          value: 'SD'
        },
        {
          label: 'Tennessee',
          value: 'TN'
        },
        {
          label: 'Texas',
          value: 'TX'
        },
        {
          label: 'Utah',
          value: 'UT'
        },
        {
          label: 'Vermont',
          value: 'VT'
        },
        {
          label: 'Virginia',
          value: 'VA'
        },
        {
          label: 'Washington',
          value: 'WA'
        },
        {
          label: 'West Virginia',
          value: 'WV'
        },
        {
          label: 'Wisconsin',
          value: 'WI'
        },
        {
          label: 'Wyoming',
          value: 'WY'
        }
      ],
      label: {
        en: 'State*',
        es: 'Estado*',
        hy: 'Նահանգ*',
        km: 'រដ្ឋ*',
        ko: '주*',
        zh: '州*',
        ja: 'ステイト*',
        ru: 'Штат*',
        th: 'รัฐ*',
        vi: 'Tiểu bang*'
      },
      required: true,
      stateKey: 'state'
    },
    {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'Zip Code*',
        es: 'Código postal*',
        hy: 'Փոստային կոդ*',
        km: 'ហ្ស៊ីបកូដ*',
        ko: 'ZIP 코드*',
        zh: '郵遞區號*',
        ja: 'ZIP コード*',
        ru: 'Почтовый индекс*',
        th: 'รหัสไปรษณีย์*',
        vi: 'Mã vùng*'
      },
      maxLength: 5,
      required: true,
      stateKey: 'zip'
    },
  ],
  radioGroupSection: {
    title: {
      en: 'Tell us about your experience',
      es: 'Cuéntenos sobre su experiencia.',
      hy: 'Պատմեք մեզ Ձեր փորձառության մասին։',
      km: 'សូមប្រាប់យើងអំពីបទពិសោធន៍របស់អ្នក។',
      ko: '귀하의 경험에 대해 말씀해주십시오.',
      zh: '分享您的經驗',
      ja: 'あなたの経験についてお知らせください。',
      ru: 'Поделитесь с нами своим мнением.',
      th: 'บอกเราเกี่ยวกับประสบการณ์ของคุณ',
      vi: 'Hãy chia sẻ với chúng tôi về kinh nghiệm của bạn.'
    },
    transitType: {
      inputType: INPUT_TYPE.radio,
      radioGroupItems: [
        {
          label: {
            en: 'Bus',
            es: 'Autobús',
            hy: 'Ավտոբուս',
            km: 'រថយន្តក្រុង',
            ko: '버스',
            zh: '公車',
            ja: 'Bus',
            ru: 'Автобус',
            th: 'Bus',
            vi: 'Xe Buýt'
          },
          value: 'bus'
        },
        {
          label: {
            en: 'Rail',
            es: 'Tren',
            hy: 'Երկաթուղի',
            km: 'រថភ្លើង',
            ko: '지하철',
            zh: '鐵路',
            ja: 'Rail',
            ru: 'Метро',
            th: 'Rail',
            vi: 'Tàu Điện'
          },
          value: 'rail'
        },
        {
          label: {
            en: 'Micro Transit',
            es: 'Micro Transit',
            hy: 'Micro Transit',
            km: 'Micro Transit',
            ko: 'Micro Transit',
            zh: 'Micro Transit',
            ja: 'Micro Transit',
            ru: 'Micro Transit',
            th: 'Micro Transit',
            vi: 'Micro Transit'
          },
          value: 'micro transit'
        },
        {
          label: {
            en: 'Bike Share',
            es: 'Bike Share',
            hy: 'Bike Share',
            km: 'Bike Share',
            ko: 'Bike Share',
            zh: 'Bike Share',
            ja: 'Bike Share',
            ru: 'Bike Share',
            th: 'Bike Share',
            vi: 'Bike Share'
          },
          value: 'bike share'
        },
        {
          label: {
            en: 'General Comments',
            es: 'General Comments',
            hy: 'General Comments',
            km: 'General Comments',
            ko: 'General Comments',
            zh: 'General Comments',
            ja: 'General Comments',
            ru: 'General Comments',
            th: 'General Comments',
            vi: 'General Comments'
          },
          value: 'general comments'
        }
      ],
      label: {
        en: 'Transportation Type*',
        es: 'Autobús o tren*',
        hy: 'Ավտոբուս, թե՞ երկաթուղի*',
        km: 'រថយន្តក្រុង ឬ រថភ្លើង*',
        ko: '버스 또는 지하철*',
        zh: '公車或鐵路*',
        ja: 'Bus または Rail*',
        ru: 'Автобус или метро*',
        th: 'Bus หรือ Rail*',
        vi: 'Xe Buýt hay Tàu Điện*'
      },
      required: true,
      stateKey: 'transitType'
    }
  },
  reportForm: {
    incidentDate: {
      inputType: INPUT_TYPE.date,
      label: {
        en: 'Date of incident*',
        es: 'Fecha del incidente*',
        hy: 'Դեպքի ամսաթիվը*',
        km: 'កាលបរិច្ឆេទនៃឧបត្តិហេតុ*',
        ko: '사고 일자*',
        zh: '事件日期*',
        ja: '事件の日付*',
        ru: 'Дата происшествия*',
        th: 'วันที่เกิดเหตุ*',
        vi: 'Ngày xảy ra sự cố*'
      },
      required: true,
      stateKey: 'incidentDateTime'
    },
    incidentTime: {
      inputType: INPUT_TYPE.time,
      label: {
        en: 'Time of incident*',
        es: 'Horario del incidente*',
        hy: 'Դեպքի ժամը*',
        km: 'ម៉ោងឧបត្តិហេតុ*',
        ko: '사고 시간*',
        zh: '事件時間*',
        ja: '事件の時間*',
        ru: 'Время происшествия*',
        th: 'เวลาที่เกิดเหตุ*',
        vi: 'Thời điểm xảy ra sự cố*'
      },
      required: true,
      stateKey: 'incidentDateTime'
    },
    busNumber: {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'Bus number',
        es: 'Número de autobús',
        hy: 'Ավտոբուսի համարը',
        km: 'លេខរថយន្តក្រុង',
        ko: '버스 번호',
        zh: '公車編號',
        ja: 'Bus 番号',
        ru: 'Номер автобуса',
        th: 'เบอร์รถประจำทาง',
        vi: 'Số xe buýt'
      },
      required: false,
      stateKey: 'busCarVehicleNumber'
    },
    carNumber: {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'CAR number',
        es: 'Número de vagón',
        hy: 'Վագոնի համարը',
        km: 'លេខទូរថភ្លើង',
        ko: '차량 번호',
        zh: '車號',
        ja: 'CAR 番号',
        ru: 'Номер вагона',
        th: 'หมายเลข CAR',
        vi: 'Số XE'
      },
      required: false,
      stateKey: 'busCarVehicleNumber'
    },
    boardingLocation: {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'Boarding location (intersection)*',
        es: 'Lugar de abordaje (intersección)*',
        hy: 'Նստելու վայրը (խաչմերուկ)*',
        km: 'កន្លែងឡើងជិះ (ផ្លូវប្រសព្វ)*',
        ko: '탑승 위치 (교차로)*',
        zh: '搭乘地點（交叉路口）*',
        ja: '乗車場所(交差点}*',
        ru: 'Место посадки (перекресток)*',
        th: 'สถานที่ขึ้น (แยก)*',
        vi: 'Địa điểm lên xe (Giao Lộ)*'
      },
      maxLength: 250,
      required: true,
      stateKey: 'location'
    },
    description: {
      inputType: INPUT_TYPE.textarea,
      label: {
        en: 'Description*',
        es: 'Descripción*',
        hy: 'Նկարագրություն*',
        km: 'ការពិពណ៌នា*',
        ko: '설명*',
        zh: '描述說明*',
        ja: '概要*',
        ru: 'Описание*',
        th: 'รายละเอียด*',
        vi: 'Mô tả*'
      },
      maxLength: 2000,
      required: true,
      stateKey: 'description'
    },
    operatorBadgeNumber: {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'Operator badge number',
        es: 'Número de placa del operador',
        hy: 'Օպերատորի նշանի համարը',
        km: 'លេខប័ណ្ណសម្គាល់ខ្លួន ប្រតិបត្តិករ',
        ko: '운전자 배지 번호',
        zh: '駕駛員識別證編號',
        ja: '運転手のバッヂ番号',
        ru: 'Номер значка оператора',
        th: 'หมายเลขตราของผู้ปฏิบัติการ',
        vi: 'Số Huy Hiệu của Người Điều Hành'
      },
      required: false,
      stateKey: 'operatorBadgeNumber',
      title: {
        en: 'Operator Information (optional)',
        es: 'Información del Operador (opcional)',
        hy: 'Օպերատորի մասին տեղեկություններ (կամընտիր)',
        km: 'ព័ត៌មានប្រតិបត្តិករ (មានជម្រើស)',
        ko: '운전자 정보 (선택 사항)',
        zh: '駕駛員資料（選填)',
        ja: '運転手についての情報 (オプション任意)',
        ru: 'Сведения об операторе (по желанию)',
        th: 'ข้อมูลผู้ปฏิบัติการ (กรอกหรือไม่ก็ได้)',
        vi: 'Thông Tin về Người Điều Hành (Không Bắt Buộc)'
      }
    },
    operatorDescription: {
      inputType: INPUT_TYPE.textarea,
      label: {
        en: 'Operator description',
        es: 'Descripción del operador',
        hy: 'Օպերատորի նկարագրությունը',
        km: 'ការពិពណ៌នាអំពីប្រតិបត្តិករ',
        ko: '운전자 설명',
        zh: '駕駛員描述說明',
        ja: '運転手の外観',
        ru: 'Описание оператора',
        th: 'รายละเอียดของผู้ปฏิบัติการ',
        vi: 'Mô Tả Người Điều Hành'
      },
      maxLength: 1000,
      required: false,
      stateKey: 'operatorDescription'
    },
    microTransitZone: {
      inputType: INPUT_TYPE.select,
      dropDownItems: [
        {label: 'Watts/Compton', value: 'watts/compton'},
        {label: 'LAX/Inglewood', value: 'lax/inglewood'},
        {label: 'North Hollywood/Burbank', value: 'north hollywood/burbank'},
        {label: 'El Monte', value: 'el monte'},
        {label: 'Highland Park/Eagle Rock/Glendale', value: 'highland park/eagle rock/glendale'},
        {label: 'Altadena/Pasadena/Sierra Madre', value: 'altadena/pasadena/sierra madre'},
        {label: 'Northwest San Fernando Valley', value: 'northwest san fernando valley'},
        {label: 'UCLA/Westwood/VA Medical Center', value: 'ucla/westwood/va medical center'}
      ],
      label: {
        en: 'Service Zone Locations*',
        es: 'Service Zone Locations*',
        hy: 'Service Zone Locations*',
        km: 'Service Zone Locations*',
        ko: 'Service Zone Locations*',
        zh: 'Service Zone Locations*',
        ja: 'Service Zone Locations*',
        ru: 'Service Zone Locations*',
        th: 'Service Zone Locations*',
        vi: 'Service Zone Locations*'
      },
      required: true,
      stateKey: 'microTransitZone',
    },
    bikeShareStation: {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'Bike Share Location*',
        es: 'Bike Share Location*',
        hy: 'Bike Share Location*',
        km: 'Bike Share Location*',
        ko: 'Bike Share Location*',
        zh: 'Bike Share Location*',
        ja: 'Bike Share Location*',
        ru: 'Bike Share Location*',
        th: 'Bike Share Location*',
        vi: 'Bike Share Location*'
      },
      required: true,
      stateKey: 'location',
    },
    tapCard: {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'TAP Card Number (0160/0170)',
        es: 'Número de Tarjeta TAP (0160/0170)',
        hy: 'TAP քարտի համարը (0160/0170)',
        km: 'លេខកាដ TAP (0160/0170)',
        ko: 'TAP 카드 번호 (0160/0170)',
        zh: 'TAP 卡号 (0160/0170)',
        ja: 'TAPカード番号 (0160/0170)',
        ru: 'Номер карты TAP (0160/0170)',
        th: 'หมายเลขบัตร TAP (0160/0170)',
        vi: 'Số Thẻ TAP (0160/0170)'
      },
      required: false,
      stateKey: 'tapCard'
    },
    generalCommentsIncidentLocation: {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'Incident Location',
        es: 'Ubicación del incidente',
        hy: 'Միջադեպի գտնվելու վայրը',
        km: 'ទីតាំងឧប្បត្តិហេតុ',
        ko: '사건 장소',
        zh: '事件发生地点',
        ja: 'インシデントの場所',
        ru: 'Место происшествия',
        th: 'สถานที่เกิดเหตุ',
        vi: 'Địa Điểm Sự Cố'
      },
      required: true,
      stateKey: 'location',
    },
    generalCommentsEmployeeDescription: {
      inputType: INPUT_TYPE.textarea,
      label: {
        en: 'Employee Description',
        es: 'Descripción del empleado*',
        hy: 'Աշխատողի նկարագրությունը*',
        km: 'ការពិពណ៌នារបស់និយោជិត*',
        ko: '직원설명*',
        zh: '员工描述*',
        ja: '従業員の説明*',
        ru: 'Описание сотрудника*',
        th: 'คำอธิบายของพนักงาน*',
        vi: 'Mô Tả Nhân Viên*'
      },
      maxLength: 1000,
      required: false,
      stateKey: 'operatorDescription',
    },
    generalCommentsLicensePlateNumber: {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'License Plate Number',
        es: 'Número de placa',
        hy: 'Համարանիշը',
        km: 'ផ្លាកលេខ',
        ko: '번호판 번호',
        zh: '车牌号 / 車牌號碼',
        ja: 'ナンバープレートの番号',
        ru: 'Номерной знак',
        th: 'หมายเลขป้ายทะเบียน',
        vi: 'Số biển số xe'
      },
      required: false,
      stateKey: 'licensePlateNumber',
    },
    generalCommentsVehicleID: {
      inputType: INPUT_TYPE.input,
      label: {
        en: 'Vehicle ID',
        es: 'ID del vehículo',
        hy: 'Տրանսպորտային միջոցի ID',
        km: 'លេខសម្គាល់រថយន្ត',
        ko: '차량 번호',
        zh: '车辆ID',
        ja: '車両ID',
        ru: 'Номер транспортного средства',
        th: 'รหัสยานพาหนะ',
        vi: 'ID của Xe'
      },
      required: false,
      stateKey: 'busCarVehicleNumber',
    },
    followUp: {
      inputType: INPUT_TYPE.radio,
      radioGroupItems: [
        {
          label: {
            en: 'Yes',
            es: 'Sí',
            hy: 'Այո',
            km: 'បាទ/ចាស៎',
            ko: '예',
            zh: '是',
            ja: 'はい',
            ru: 'Да',
            th: 'ใช่',
            vi: 'Phải'
          },
          value: 'yes'
        },
        {
          label: {
            en: 'No',
            es: 'No',
            hy: 'Ոչ',
            km: 'ទេ',
            ko: '아니오',
            zh: '否',
            ja: 'いいえ',
            ru: 'Нет',
            th: 'ไม่',
            vi: 'Không'
          },
          value: 'no'
        }
      ],
      label: {
        en: 'Would you like a follow-up?*',
        es: '¿Le gustaría recibir un seguimiento?*',
        hy: 'Կցանկանա՞ք շարունակել:*',
        km: 'តើអ្នកចង់ធ្វើការតាមដានដែរឬទេ?*',
        ko: '후속 조치를 원하십니까?*',
        zh: '您是否需要跟进?*',
        ja: 'フォローアップをご希望ですか?*',
        ru: 'Хотите, чтобы вам ответили?*',
        th: 'คุณต้องการที่จะติดตามผลหรือไม่*',
        vi: 'Quý vị có muốn biết tình hình không?*'
      },
      required: true,
      stateKey: 'followUp'
    }
  },
  submitSuccessPage: {
    en: {
      title: 'Your form successfully submitted',
      p1: 'If your transit experience was less than satisfactory, please accept our apologies in advance, \
          as well as our assurances that, based on the information you provided, the matter will be investigated.',
      p2: {
        text: 'Follow up by telephone or e-mail should refer to ',
        comment: 'comment #',
        closingTxt: ''
      },
      p3: 'If you took this opportunity to commend a Metro team member for outstanding performance, your \
          positive comments will be sent to the appropriate manager and shared with the employee.',
      p4: 'Thank you for taking time to contact us.',
      contact: {
        title: 'Metro Customer Relations',
        building: 'One Gateway Plaza',
        address: 'Los Angeles, CA 90012',
        link: 'mailto:CustomerRelations@Metro.net',
        phone: '213.922.6235 or 800.464.2111 '
      }
    },
    es: {
      title: 'Su formulario ha sido enviado correctamente',
      p1: 'Si su experiencia de transporte fue menos que satisfactoria, por favor acepte \
          nuestras disculpas de antemano, así como nuestra garantía de que, según la información \
          que nos proporcionó, el asunto será investigado.',
      p2: {
        text: 'El seguimiento por teléfono o correo electrónico debe hacer referencia al ',
        comment: 'comentario #',
        closingTxt: ''
      },
      p3: 'Si utilizó esta oportunidad para felicitar a un miembro del equipo de Metro por su excelente \
          desempeño, sus comentarios positivos se enviarán al gerente correspondiente y se le compartirán \
          al empleado.',
      p4: 'Gracias por tomarse el tiempo para contactarnos.',
    },
    hy: {
      title: 'Ձեր ձևաթուղթը հաջողությամբ ներկայացվել է։',
      p1: 'Եթե Ձեր փոխադրման փորձառությունը Ձեզ չի բավարարել, նախապես խնդրում ենք ընդունել \
          մեր ներողությունը, ինչպես նաև մեր վստահեցումը, որ, Ձերտրամադրված տեղեկությունների հիման վրա, \
          հարցը կքննարկվի։',
      p2: {
        text: 'Այս հարցին հեռախոսով կամ էլ. նամակով հետևելու համար հղում կատարեք ',
        comment: 'մեկնաբանություն #',
        closingTxt: ''
      },
      p3: 'abcԵթե Դուք օգտագործել եք այս հնարավորությունը լավ աշխատանքի համար Metro-ի թիմի անդամին \
          գովելու համար, Ձեր դրական մեկնաբանությունները կուղարկվեն համապատասխան \
          մենեջերին և կտեղեկացվեն աշխատակցին։',
      p4: 'Շնորհակալություն, որ ժամանակ եք հատկացրել մեզ հետ կապվելու համար։',
    },
    km: {
      title: 'សំណុំបែបបទរបស់អ្នក បានបញ្ជូនដោយជោគជ័យ។',
      p1: 'ប្រសិនបើបទពិសោធន៍ធ្វើដំណើរឆ្លងកាត់របស់អ្នកមានការពេញចិត្តតិចតួច សូមមេត្តាទទួលយកការសុំអភ័យទោសជាមុន \
          ព្រមទាំងមានការធានារបស់យើងថា ដោយផ្អែកលើព័ត៌មានដែលអ្នកបានផ្តល់ជូននោះ បញ្ហានេះនឹងត្រូវស៊ើបអង្កេត។',
      p2: {
        text: 'ការតាមដានតាមទូរស័ព្ទ ឬអ៊ីម៉ែល ត្រូវសំដៅចំពោះ',
        comment: 'មតិយោបល់ #',
        closingTxt: ''
      },
      p3: 'ប្រសិនបើលោកអ្នកបានទទួលឱកាសនេះ ដើម្បីលើកសរសើរសមាជិកក្រុមការងារ Metro \
          ចំពោះការអនុវត្តដ៏ល្អប្រសើរនោះ មតិយោបល់របស់អ្នកប្រកបដោយក្តីសង្ឃឹម នឹងត្រូវបានបញ្ជូនទៅប្រធានគ្រប់គ្រងដែលសមស្រប \
          និងត្រូវចែករំលែកជាមួយនិយោជិត។',
      p4: 'សូមអរគុណលោកអ្នកចំពោះការចំណាយពេលវេលាដើម្បីទាក់ទងមកយើងខ្ញុំ។',
    },
    ko: {
      title: '귀하의 양식이 성공적으로 접수되었습니다',
      p1: '귀하의 교통 서비스 이용 경험이 만족스럽지 않으시다면 우선 사과를 드리며 귀하가 제공해주시는 \
          내용은 조사하도록 하겠습니다.',
      p2: {
        text: '전화 또는 이메일로 관련하여 연락을 주실 경우는 ',
        comment: '의견 번호 ',
        closingTxt: '를 말씀해주십시오.'
      },
      p3: '메트로 팀에게 훌륭한 성과에 대해 의견을 주시고 싶으시다면 귀하의 긍정적인 의견은 해당 관리자에게 전달되고 \
        그 직원과 공유될 것입니다.',
      p4: '저희에게 연락주셔서 감사합니다.'
    },
    zh: {
      title: '您的意見表已傳送成功。',
      p1: '若您的轉乘經驗不甚滿意，請先接受我們的道歉，我們也保證將根據您提供的資料進行調查。',
      p2: {
        text: '後續電話或電子郵件應參考意見',
        comment: '',
        closingTxt: ''
      },
      p3: '若您藉此機會稱讚Metro團隊人員的優秀表現，您的正面意見將會寄至相關經理再告知員工。',
      p4: '感謝您撥空聯絡我們。'
    },
    ja: {
      title: 'あなたのフォームは無事に提出されました',
      p1: 'もしあなたのトランジットでの経験が不満足であった場合には前もってお詫びいたします。\
        そしてあなたが提供された情報に基づいてこの件についての調査は確実に行います。',
      p2: {
        text: '',
        comment: '#',
        closingTxt: 'のコメントに関するフォローアップを電話またはEメイルで行ってください'
      },
      p3: 'もしあなたがこの機会にMetroチームメンバーの優れた行いについてのお褒めの言葉をかけたいときにはあなた\
        のお褒めのコメントはその従業員とシェアできるように適切なマネージャーの元に送られます。',
      p4: 'お忙しいところ私共にご連絡を頂きましてありがとうございました。'
    },
    ru: {
      title: 'Форма отправлена',
      p1: 'Если ваша поездка была неудовлетворительной, заранее примите наши извинения и \
        уверения в том, что на основании предоставленной вами информации будет проведено \
        соответствующее расследование.',
      p2: {
        text: 'В дальнейшем при обращении по телефону или электронной почте сообщите ',
        comment: 'номер комментария ',
        closingTxt: ''
      },
      p3: 'Если вы воспользовались этой возможностью, чтобы похвалить сотрудника \
        Metro за превосходное обслуживание, ваша благодарность будет направлена соответствующему \
        руководителю и доведена до сведения сотрудника.',
      p4: 'Благодарим вас за то, что уделили время и оставили отзыв.'
    },
    th: {
      title: 'ส่งแบบฟอร์มคุณเรียบร้อยแล้ว',
      p1: 'หากคุณไม่พอใจประสบการณ์การโดยสารของคุณ เราขอแสดงความเสียใจล่วงหน้า \
        และเราขอรับประกันว่าตามข้อมูลที่คุณส่งเข้ามา เราจะทำการสอบสวนเรื่องนี้',
      p2: {
        text: 'หากคุณติดตามผลเรื่องนี้ทางโทรศัพท์หรืออีเมล โปรดระบุความเห็นหมายเลข ',
        comment: '',
        closingTxt: ''
      },
      p3: 'หากคุณแสดงความเห็นชื่นชมผลงานของสมาชิกทีม Metro คนใดที่ทำงานได้ดีเยี่ยม \
        เราจะส่งความเห็นดังกล่าวไปยังผู้จัดการฝ่ายที่เหมาะสมและแจ้งให้พนักงานทราบ',
      p4: 'ขอบคุณที่สละเวลาติดต่อเรา'
    },
    vi: {
      title: 'Mẫu của bạn đã được gửi đi thành công',
      p1: 'Nếu kinh nghiệm vận chuyển của bạn không thỏa đáng, vui lòng nhận lời xin lỗi \
        trước của chúng tôi cũng như chúng tôi đảm bảo rằng dựa trên thông tin bạn cung cấp, \
        vấn đề đó sẽ được điều tra.',
      p2: {
        text: 'Khi theo dõi qua điện thoại hoặc e-mail nên đề cập ',
        comment: 'ý kiến đóng góp số #',
        closingTxt: ''
      },
      p3: 'Nếu quý vị muốn nhân cơ hội này để khen ngợi một thành viên của đội ngũ Metro vì \
        làm việc với hiệu suất vượt trội, ý kiến tích cực của bạn sẽ được gửi đến người quản lý \
        thích hợp và chia sẻ với nhân viên đó.',
      p4: 'Cảm ơn bạn đã dành thời gian liên hệ với chúng tôi.'
    },
  },
};

export default formText;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import AppRoutes from 'routes';
// import reportWebVitals from './reportWebVitals';

const ROOT_ID = 'root';

createRoot(document.getElementById(ROOT_ID) as HTMLElement).render(
  <RouterProvider
    router={AppRoutes}
    fallbackElement={<div>Error</div>}
  />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, { useContext, useEffect, useState } from 'react';
import { FormInputsObject } from 'containers/customer_comment_form/text';
import { GridWrapper, Inputs } from 'components';
import { CustomerCommentFormContext } from 'context';
import { DropdownItems, INPUT_TYPE } from 'containers/customer_comment_form/text';

interface BusRailDropdownsProps {
  type: 'lines' | 'destinations' | 'boardingLocationDropDown'
}

const formInputLabelsObjectDestinations = {
  en: 'Destination*',
  es: 'Destino*',
  hy: 'Նշանակման վայրը*',
  km: 'ទិសដៅ*',
  ko: '목적지*',
  zh: '目的地*',
  ja: '目的地*',
  ru: 'Пункт назначения*',
  th: 'จุดหมายปลายทาง*',
  vi: 'Điểm đến*'
}

const formInputLabelsObjectBoardingLocation = {
  en: 'Boarding location (intersection)*',
  es: 'Lugar de abordaje (intersección)*',
  hy: 'Նստելու վայրը (խաչմերուկ)*',
  km: 'កន្លែងឡើងជិះ (ផ្លូវប្រសព្វ)*',
  ko: '탑승 위치 (교차로)*',
  zh: '搭乘地點（交叉路口）*',
  ja: '乗車場所(交差点}*',
  ru: 'Место посадки (перекресток)*',
  th: 'สถานที่ขึ้น (แยก)*',
  vi: 'Địa điểm lên xe (Giao Lộ)*'
}

const BusRailDropdowns: React.FC<BusRailDropdownsProps> = ({ type }) => {
  const [inputsObject, setInputsObject] = useState<FormInputsObject>({
    inputType: '',
    dropDownItems: [],
    label: {},
    required: false,
    stateKey: '',
    title: {},
    type: '',
  });
  const [destinationsInputsObject, setDestinationsInputsObject] = useState<FormInputsObject>({
    inputType: '',
    dropDownItems: [],
    label: {},
    required: false,
    stateKey: '',
    title: {},
    type: '',
  });

  const formData = useContext(CustomerCommentFormContext);
  const { transitType } = formData.state;
  const isBus = formData?.state?.transitType?.value === 'bus';
  const formInputsLabelObjectDestinations =
    type === 'destinations' ? formInputLabelsObjectDestinations : formInputLabelsObjectBoardingLocation

  useEffect(() => {
    /** 
     * Loop through API response for Bus Lines and create array of {value, label} objects  
     * which <Inputs/> component expects for select items
     */
    const LINES: DropdownItems[] = [];
    if (formData.state.transitType.value === 'rail') {
      formData.railLines?.forEach((line) => LINES.push({ value: line.lineId, label: line.lineName }));
    } else if (formData.state.transitType.value === 'bus') {
      formData.busLines?.forEach((line) => LINES.push({ value: line.lineNumber, label: line.lineName }));
    }

    /**
     * Create object of type <FormInputsObject> that <Inputs/> component expects as a prop
     * to create label, values etc for form fields
     */
    const railLineDetails: FormInputsObject = {
      inputType: INPUT_TYPE.select,
      dropDownItems: LINES,
      label: {
        en: isBus ? 'Line Number*' : 'Rail line*',
        es: isBus ? 'Número de línea*' : 'Línea ferroviaria*',
        hy: isBus ? 'Երթուղու համարը*' : 'Երկաթուղային գիծ*',
        km: isBus ? 'លេខបន្ទាត់*' : 'ផ្លូវរថភ្លើង*',
        ko: isBus ? '버스 노선*' : '지하철 노선*',
        zh: isBus ? '路線編號*' : '鐵路路線*',
        ja: isBus ? 'Line 番号*' : 'Rail Line 名*',
        ru: isBus ? 'Номер линии*' : 'Линия метро*',
        th: isBus ? 'สายรถประจำทาง*' : 'รถไฟ*',
        vi: isBus ? 'Tuyến xe*' : 'Tuyến tàu điện*'
      },
      required: true,
      stateKey: 'busRailLine'
    };
    setInputsObject(railLineDetails);

    /** 
     * Loop through API destinations response and create array of {value, label} objects 
     * which <Inputs/> component expects for select items
     */
    const destinations: DropdownItems[] = [];
    if (formData.state.busRailLine.value.length > 0 && formData?.destinations) {
      const busLineID: string = String(formData.state.busRailLine.value);
      // const filtered = formData.destinations[transitType.value]
      formData.destinations[transitType.value]?.forEach((dest) => {
        if (busLineID === dest.busRailId) {
          destinations.push({ value: dest.stopId, label: dest.stopName });
        }
      });
    }

    const destinationDetails: FormInputsObject = {
      inputType: INPUT_TYPE.select,
      dropDownItems: destinations,
      label: formInputsLabelObjectDestinations,
      required: true,
      stateKey: type === 'destinations' ? 'destination' : type === 'boardingLocationDropDown' ? 'location' : ''
    };

    setDestinationsInputsObject(destinationDetails);
  }, [formData.busLines, formData.destinations, formData.railLines, formData.state.busRailLine]);

  if (type === 'lines') {
    return (
      <Inputs form={inputsObject} />
    );
  } else if (type === 'destinations' || type === 'boardingLocationDropDown') {
    return (
      <Inputs form={destinationsInputsObject} />
    );
  }

  return <></>;
};

export default BusRailDropdowns;

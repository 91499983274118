import React from "react";
import { createBrowserRouter, Navigate, useRouteError } from "react-router-dom";
import { CustomerCommentForm, PageNotFound, SubmitSuccessPage } from 'containers';
import { App } from "containers";

function ErrorBoundary() {
  const error = useRouteError();
  console.error('Router error boundary', error);
  return (
    <PageNotFound />
  );
}

const AppRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to='/customercomments' replace={true} />
      },
      {
        path: '/customercomments',
        element: <CustomerCommentForm />
      },
      {
        path: '/customercomments/createsuccess/:comment_id',
        element: <SubmitSuccessPage />
      },
    ]
  }
]);

export default AppRoutes;

import React from 'react';
import { Grid, GridOwnProps } from '@mui/material';

type ScreenSizes = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

interface GridWrapperProps {
  children: React.ReactNode;
  columns?: ScreenSizes;
  container?: boolean | undefined;
  item?: boolean;
  spacing?: ScreenSizes;
  styles?: object;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

const GridWrapper: React.FC<GridWrapperProps> = ({ children, columns, container, item, spacing, styles, xs, sm, md, lg, xl }) => {
  return (
    <Grid
      item={item ? item : undefined}
      container={container ? container : undefined}
      columns={columns}
      spacing={spacing && container ? spacing : undefined}
      xs={xs && xs}
      sm={sm && sm}
      md={md && md}
      lg={lg && lg}
      xl={xl && xl}
      sx={styles && {...styles}}
    >
      {children}
    </Grid>
  );
};

export default GridWrapper;

import React from 'react';

const MenuIconHamburger: React.FC = (): JSX.Element => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 1.53333H18" stroke="white" strokeWidth="2"/>
    <path d="M0 9.00001H18" stroke="white" strokeWidth="2"/>
    <path d="M0 16.4667H18" stroke="white" strokeWidth="2"/>
  </svg>
);

export default MenuIconHamburger;

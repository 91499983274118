import {
  Theme
} from '@mui/material';

const customerFormStyles = (theme: Theme) => {
  return {
    container: {
      maxWidth: {
        xs: theme.breakpoints.values['sm'],
        md: theme.breakpoints.values['md'],
        lg: theme.breakpoints.values['lg']
      },
      color: theme.custom.black
    },
    headers: {
      pageTitle: {
        typography: {
          xs: 'h5',
          md: 'h4'
        }
      },
      subTitles: {
        typography: {
          xs: 'h6',
          md: 'h5'
        }
      }
    },
    bold: {
      fontWeight: 'bold',
    },
    warningColor: {
      color: theme.palette.warning.main
    },
    fontStyles: {
      italic: {
        fontStyle: 'italic'
      }
    },
    textField: {
      [theme.breakpoints.only('xs')]: {
        width: '100%',
      },
    },
    formSubmtSuccess: {
      fontColor: {
        color: '#00bfff'
      },
      contactInfo: {
        display: 'flex',
        justifyContent: 'flex-end'
      }
    }
  };
};

export default customerFormStyles;

import React from 'react';
import { Footer, Navbar, LinkComponent } from "components";
import { Container, ThemeProvider, Typography } from '@mui/material';
import { MainTheme } from "ts_mui_modules";
import './styles.css';

const PageNotFound: React.FC = (): JSX.Element => {
  return (
    <>
      <ThemeProvider theme={MainTheme}>
        <Navbar hideLanguageBtn={true} />
        <Container component='div'>
          <Typography variant='body2' gutterBottom>Error 404: Page not found</Typography>
          <br />
          <br />
          <br />
          <Typography variant='h3' gutterBottom>Page not found</Typography>
          <br />
          <Typography variant='h6' gutterBottom>Hmm, looks like we can't find that page.</Typography>
          <br />
          <Typography variant='h6' gutterBottom>
            We're sorry. Would you like to visit the&nbsp;
            <LinkComponent isReactRoute={false} url='https://www.metro.net/'>
              metro.net homepage
            </LinkComponent> instead?
          </Typography>
        </Container>
        <Footer />
      </ThemeProvider>
    </>
  )
}

export default PageNotFound;

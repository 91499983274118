import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const FullPagfeCircleLoader: React.FC = (): JSX.Element => (

  <Box component={`div`} sx={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'black',
    opacity: 0.5,
    zIndex: 2000,
  }}>
    <CircularProgress thickness={5} color='primary' sx={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      zIndex: 2001,
    }} />
  </Box>
);

export default FullPagfeCircleLoader;

import React from 'react';
import { Link } from 'react-router-dom';
import {
  Link as MUILink,
} from '@mui/material';

interface LinkComponentPropTypes {
  children: React.ReactNode
  isReactRoute: boolean
  styles?: object
  url: string
}

const LinkComponent: React.FC<LinkComponentPropTypes> = ({ children, isReactRoute, styles, url }): JSX.Element => {
  if (!isReactRoute) {
    return (
      <MUILink
        href={url ?? "#"}
        target="_blank"
        underline="hover"
        sx={styles}
      >
        {children}
      </MUILink>
    );
  }

  return <Link to={url}>{children}</Link>;
};

export default LinkComponent;

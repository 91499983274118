import { Theme } from '@mui/material';

const styles = (theme: Theme) => {
    return {
      appbar: {
        backgroundColor: theme.custom.black,
        zIndex: theme.zIndex.drawer + 1
      },
      container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: {
          xs: theme.breakpoints.values['sm'],
          md: theme.breakpoints.values['md'],
          lg: '1235px',
        },
        minHeight: {
          xs: '50px',
          md: '72px'
        },
      },
      drawer: {
        width: 240,
        textAlign: 'center',
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
      },
      listMenu: {
        boxShadow: `1px 1px 5px ${theme.custom.black}`,
        zIndex: 10
      },
      metroIcon: {
        justifyContent: 'center',
        [theme.breakpoints.between('xs', 'md')]: {
          textAlign: 'center',
          flex: '1 1 0px'
        }
      },
      menuIconButton: {
        justifyContent: 'flex-start',
        flex: '1 1 0px'
      },
      colorWhite: {
        color: theme.custom.white
      },
      translateIconButton: {
        color: theme.custom.white,
        justifyContent: 'flex-end',
        [theme.breakpoints.between('xs', 'md')]: {
          textAlign: 'center',
          flex: '1 1 0px'
        }
      },
      translateIconBox: {
        color: theme.custom.white,
        width: {
          xs: '18px',
        },
        marginRight: '5px'
      },
      hideMobileItem: {
        [theme.breakpoints.up('md')]: {
          display: 'none'
        }
      },
      hideDesktopItem: {
        [theme.breakpoints.down('sm')]: {
          display: 'none'
        }
      },
      desktopLinksContainer: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: {
          xs: 'none',
          md: 'flex'
        }
      },
      desktopLinks: {
        textAlign: 'justify',
        color: 'white',
        display: 'block'
      }
    };
  };
  
export default styles;

import React, { useContext, useState, useRef } from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import {
  Box,
  Container,
  Drawer,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  useTheme
} from '@mui/material';
import { styles } from 'components/navbar';
import { CustomerCommentFormContext } from 'context';
import { DropdownItems } from 'containers/customer_comment_form/text';
import { LinkComponent, MobileMenuLeft } from 'components';
import { MenuIconHamburger, MetroLogo, MetroLogoXS, TranslateLanguageIcon } from 'svg';
import formText from 'containers/customer_comment_form/text';
import navbarText from 'components/navbar/text';

type NavbarPropTypes = {
  hideLanguageBtn?: boolean
}

const Navbar: React.FC<NavbarPropTypes> = ({ hideLanguageBtn = false }): JSX.Element => {
  const formData = useContext(CustomerCommentFormContext);
  const language = formData.state.language.value;
  const [languageAnchorEl, setLanguageAnchorEl] = React.useState<null | HTMLElement>(null);
  const languageMenuIsOpen = Boolean(languageAnchorEl);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const theme = useTheme();
  const langaugeBtnRef = useRef(null);

  const handleClick = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  }

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  }

  const handleClose = () => {
    setMobileMenuIsOpen(false);
  }

  const handleLanguageBtnClick = () => {
    setLanguageAnchorEl(langaugeBtnRef.current);
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, language: string) => {
    formData?.handleLanguageChange && formData.handleLanguageChange(language);
    handleLanguageMenuClose();
  };

  return (
    <>
      <AppBar sx={() => styles(theme).appbar} position='sticky'>
        <Toolbar>
          <Container
            sx={() => styles(theme).container}
          >
            <IconButton onClick={handleClick} sx={() => {
              return {
                ...styles(theme).menuIconButton,
                ...styles(theme).hideMobileItem,
              };
            }}>
              <SvgIcon component={MenuIconHamburger} sx={() => styles(theme).colorWhite} />
            </IconButton>
            <LinkComponent
              isReactRoute={false}
              styles={() => styles(theme).metroIcon}
              url={navbarText.links[0].url}
            >
              <IconButton sx={() => {
                return {
                  ...styles(theme).hideMobileItem
                };
              }}>
                <SvgIcon component={MetroLogoXS} />
              </IconButton>
            </LinkComponent>
            <Box sx={() => styles(theme).desktopLinksContainer}>
              <LinkComponent
                isReactRoute={false}
                styles={() => styles(theme).desktopLinks}
                url={navbarText.links[0].url}
              >
                <IconButton sx={() => {
                  return {
                    ...styles(theme).hideDesktopItem,
                    ...styles(theme).metroIcon,
                    mr: '100px'
                  };
                }}>
                  <SvgIcon component={MetroLogo} />
                </IconButton>
              </LinkComponent>
              {navbarText.links.map(({ isReactRoute, text, url }, i) => (
                <MenuItem key={`link-${i}`}>
                  <LinkComponent
                    key={`link-md-${i}`}
                    isReactRoute={isReactRoute}
                    styles={() => styles(theme).desktopLinks}
                    url={url}
                  >
                    <ListItemText primary={text[language]} />
                  </LinkComponent>
                </MenuItem>
              ))}
            </Box>
            {!hideLanguageBtn ? (
              <IconButton
                onClick={handleLanguageBtnClick}
                ref={langaugeBtnRef}
                sx={() => {
                  return {
                    ...styles(theme).translateIconButton,
                    [theme.breakpoints.up('xs')]: {
                      flexGrow: 0
                    }
                  }
                }}>
                <Box sx={() => styles(theme).translateIconBox}>
                  <SvgIcon component={TranslateLanguageIcon} />
                </Box>
                <Typography variant="body1" sx={() => {
                  return {
                    ...styles(theme).hideDesktopItem,
                  }
                }}>Translate</Typography>
              </IconButton>
            ): null}
            <Menu
              anchorEl={languageAnchorEl}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              open={languageMenuIsOpen}
              onClose={handleLanguageMenuClose}
              MenuListProps={{
                role: 'listbox',
              }}
            >
              {formText?.personalInfoSection[0]?.dropDownItems
                && formText.personalInfoSection[0].dropDownItems.map((language: DropdownItems, index: number) => (
                  <MenuItem
                    key={`language-${language.value}-${index}`}
                    value={language.value}
                    onClick={(event) => handleMenuItemClick(event, language.value as string)}
                  >
                    {language.label}
                  </MenuItem>
                ))}
            </Menu>
          </Container>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={`left`}
        open={mobileMenuIsOpen}
        onClose={handleClose}
        sx={() => {
          return {
            ...styles(theme).hideMobileItem,
            ...styles(theme).drawer
          };
        }
        }>
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <MobileMenuLeft
            style={styles(theme).listMenu}
            textList={navbarText.links}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;

import React, { useContext } from 'react';
import {
  Box,
  Divider,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material';
import { LinkComponent } from 'components';
import { NavbarTextObject } from 'components/navbar/text';
import { CustomerCommentFormContext } from 'context';

interface MobileMenuLeftPropTypes {
  style: object;
  textList: NavbarTextObject[];
}

const MobileMenuLeft: React.FC<MobileMenuLeftPropTypes> = ({ textList }): JSX.Element => {
  const formData = useContext(CustomerCommentFormContext);
  const language = formData.state.language.value;

  return (
    <MenuList>
      {textList.map(({ isReactRoute, text, url }, i) => (
        <Box key={`xs-menu-left-item-${i}`}>
          <MenuItem>
            <LinkComponent styles={{width: '100%'}} isReactRoute={isReactRoute} url={url}>
              <ListItemText primary={text[language]} />
            </LinkComponent>
          </MenuItem>
          {i < (textList.length - 1) ? <Divider /> : null}
        </Box>
      ))}
    </MenuList>
  );
};

export default MobileMenuLeft;
